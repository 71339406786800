import React, {useState, useEffect} from 'react'

import Header from "./Header";
import Footer from "./Footer";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Refundpolicy() {
  const [forms, setforms] = useState([]);

  const getData = () => {
    axios
      .post("https://api.freedomcars.co.in/v1/app/general/getrefund_policy", {})
      .then((res) => {
        setforms(res.data.RefundPolicy);
      });
  };

  useEffect(()=>{
    getData()
  }, [])

  return (
    <div>
    <Header />{" "}
    <div>
      <main class="main">
        <Container
          class="mt-5 mb-5"
          // style={{ marginTop: "150px", marginBottom: "100px" }}
        >
          <div class="col-12 mt-5">
            <div class="main__title main__title--page">
              <div class="details">
                <h5>Refund Policy</h5>
                <div class=" splide--details details__slider">
                <div
                        className="mt-5"
                        dangerouslySetInnerHTML={{
                          __html: forms.refund_policy,
                        }}
                      ></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
      <ToastContainer />
    </div>
    <Footer />
  </div>
  )
}

export default Refundpolicy