import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import logo from "../img/freedom4.png";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Login() {
  const [user, setUser] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    let newadmin = { ...user };
    newadmin[e.target.name] = e.target.value.trim();
    setUser(newadmin);
  };

  const usersign = (e) => {
    e.preventDefault();
    signin();
  };

  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };


  const cars1 = location.state;

  const navigate = useNavigate();
  const signin = () => {
    var parse = {
      email: user.email,
      password: user.password,
    };
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/customer/customerappsignin",
        parse
      )
      .then(
        (res) => {
          console.log("success");
          toast("successfully completed the login");
          setTimeout(() => {
            sessionStorage.setItem("UserId", res.data.user.id);
            sessionStorage.setItem("name", res.data.user.name);
            sessionStorage.setItem("phone", res.data.user.phone);
            sessionStorage.setItem("email", res.data.user.email);
            sessionStorage.setItem("role", res.data.user.role);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("status", res.data.user.status);
            if (sessionStorage.getItem("status") == "true") {
              navigate("/");
              toast("successfully completed the login");
            } else {
              navigate("/Doc");
              toast("successfully completed the login");
            }
            toast.dismiss();
          }, 2000);

        },
        (error) => {
          if (error.response && error.response.status === 401) {
            toast(error.response.data.message);
          }
        }
      );
  };


  return (
    <div>
      {" "}
      <main class="main main--sign" data-bg="img/bg/bg.png">
        <div class="sign">
          <div class="sign__content">
            <form
              class="sign__form"
              onSubmit={(e) => {
                usersign(e);
              }}
              method="post"
            >
              <Link to="/" class="sign__logo">
                {/* <h3 className="zz">freedom cars</h3> */}
                <img src={logo} width="200px"></img>
              </Link>

              <div class="sign__group">
                <input
                  type="text"
                  required
                  class="sign__input"
                  placeholder="Email"
                  name="email"
                  value={user.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>

              {/* <div class="sign__group">
                <input
                  name="password"
                  class="sign__input"
                  value={user.password}
                  type="password"
                  required
                  min={6}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div> */}
              <div class="sign__group">
                <input
                  name="password"
                  class="sign__input"
                  value={user.password}
                  type={showPassword ? 'text' : 'password'}
                  required
                  // min={6}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    handleChange(e);
                  }}

                />
                <div className="textps">
                  <i
                    className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                    aria-hidden="true"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </div>





              <button class="sign__btn" type="submit">
                <span style={{ color: "white" }}>Sign in</span>
              </button>

              <span class="sign__delimiter">or</span>

              <span class="sign__text">
                Don't have an account?{" "}
                <NavLink to="/Signup">
                  <a>Sign up!</a>
                </NavLink>
              </span>

              <NavLink to="/Forgetpassword">
                {" "}
                <span class="sign__text">
                  <a>Forgot password?</a>
                </span>{" "}
              </NavLink>
            </form>

            <ToastContainer />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
