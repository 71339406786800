import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import { saveAs } from 'file-saver'
import moment from 'moment';

function Mybookings() {
  const [user, setuser] = useState([]);
  useEffect(() => {
    getCategory();
  }, []);
  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/booking/mybookings",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.bookings);
      });
  };

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = user.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(user.length / listPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [form, setform] = useState([]);

  const getdocuments = (data) => {
    // getdocument(data)
    saveAs("https://api.freedomcars.co.in/" + data.softCopy)
  }

  // const getdocument = (data) => {
  //   saveAs("https://api.freedomcars.co.in/" + data.softCopy) 
  //   // var token = sessionStorage.getItem("token");
  //   // const dataArray = new FormData();
  //   // dataArray.append("id", data._id);
  //   // axios
  //   //   .post(
  //   //     "https://api.freedomcars.co.in/v1/app/booking/getappinvoice",
  //   //     dataArray,
  //   //     {
  //   //       headers: { Authorization: `Bearer ${token}` },
  //   //     }
  //   //   )
  //   //   .then((res) => {
  //   //     setform(res.data.invoiceResult);
  //   //     saveAs("https://api.freedomcars.co.in/" + form.softCopy) 


  //   //   });
  // };


  return (
    <div>
      <Header />
      <main class="main mb-5">
        <div class="container">
          <div class="row row--grid">
            <div class="col-12">
              <div class="main__title main__title--page mb-2">
                <h5>My Bookings List</h5>
              </div>
            </div>
          </div>
          {lists.length >= 1 ? (
            <>
              <Container>
                <div>
                  {lists.map((data, i) => {
                    return (
                      <>
                        <div class="carts mb-5">
                          <Row>
                            <Col md={3}>
                              <img
                                className="d-block"
                                src={
                                  "https://api.freedomcars.co.in/" +
                                  data.cars.carImage[0]
                                }
                                width="100%" height="100%"
                              />
                            </Col>
                            <Col md={9} className="mt-3 text-center">
                              <Row>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Booking Id :</b>
                                    <span> {data.booking_id}</span>
                                  </p>{" "}
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  {" "}
                                  <p>
                                    <b>Car Model Name :</b>
                                    <span> {data.carModelName}</span>
                                  </p>{" "}
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Pickup Date :</b>
                                    <span> {data.fromDate}</span>
                                  </p>
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Delivery Date :</b>
                                    <span> {data.toDate}</span>
                                  </p>{" "}
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Pickup Time :</b>
                                    <span> {moment(data.fromTime, 'HH:mm').format('hh:mm A')}</span>
                                  </p>
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Delivery Time :</b>
                                    <span> {moment(data.toTime, 'HH:mm').format('hh:mm A')}</span>
                                  </p>{" "}
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Payment :</b>
                                    <span> {data.paymentStatus == 1 ? "Full Payment" : "Partial Payment"}</span>
                                  </p>{" "}
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  {" "}
                                  <p>
                                    <b>Price :</b>
                                    <span> {data.totalprice}</span>
                                  </p>{" "}
                                </div>
                                <div  className="col-lg-4 col-sm-6  ">
                                  <p>
                                    <b>Status :</b>
                                    <span> {data.status}</span>
                                  </p>{" "}
                                </div>

                              </Row>
                              <Row>
                                {data.status == "accepted" ? (
                                  <Col className="text-end" xl={11}>
                                    <i type="button"
                                      class="fa fa-download mb-4  text-info"
                                      aria-hidden="true"
                                      onClick={() => {
                                        getdocuments(data);
                                      }}
                                    >
                                      {" "}
                                      Invoices
                                    </i>{" "}
                                  </Col>
                                ) : ""}

                              </Row>
                            </Col>

                          </Row>

                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="mt-3 " style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                </div>
              </Container>
            </>
          ) : (
            <div style={{ height: "200px" }}>
              <center style={{ marginTop: "120px" }}>
                <h4>No Data Found</h4>
              </center>
            </div>
          )}

          {/* <div class="col-12">
              <div class="cart">
                <div class="cart__table-wrap">
                  <div class="cart__table-scroll">
                    <Table>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Booking Id </th>
                          <th>Car Image</th>
                          <th>Car Model Name</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Price</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {lists.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{(pageNumber - 1) * 10 + i + 11}</td>
                              <td>{data.booking_id}</td>

                              <td>
                                <img
                                  className="d-block"
                                  src={
                                    "https://api.freedomcars.co.in/" +
                                    data.cars.carImage[0]
                                  }
                                  height="100px"
                                  alt="First slide"
                                />
                              </td>
                              <td>{data.carModelName}</td>
                              <td>{data.fromDate}</td>
                              <td>{data.toDate}</td>
                              <td>{data.totalprice}</td>
                              <td>{data.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>{" "}
      </main>
      <Footer />
    </div>
  );
}

export default Mybookings;
