import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

function Profile() {
  const [form, setform] = useState([]);
  const [user, setuser] = useState([]);
  console.log(user);

  const handleChange = (e) => {
    let myUser = { ...user };
    myUser[e.target.name] = e.target.value;
    setuser(myUser);
  };

  const handleSubmit = (e) => {
    updateCategory();
    e.preventDefault();
  };

  const addCategory = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    dataArray.append("password", form.password);
    dataArray.append("newpassword", form.newpassword);
    dataArray.append("confirmpassword", form.confirmpassword);
    axios
      .put(
        "https://api.freedomcars.co.in/v1/app/customer/customer-change-password",
        dataArray,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.messsage);
            clearForm();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.messsage);
          }
        }
      );
  };

  const clearForm = () => {
    setform({
      password: "",
      newpassword: "",
      confirmpassword: "",
    });
  };

  const handleChange1 = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    addCategory();
  };

  const [Files, setFiles] = useState("");
  const changeHandler = (e) => {
    setFiles(e.target.files);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setShowPassword2((prevState) => !prevState);
  };
  const [showPassword3, setShowPassword3] = useState(false);
  const togglePasswordVisibility3 = () => {
    setShowPassword3((prevState) => !prevState);
  };

  const updateCategory = () => {
    const data1 = user._id;
    // const zzz = {
    //   customerName: user.customerName,
    //   phone: user.phone,
    //   email: user.email,
    // };
    const dataArray = new FormData();
    dataArray.append("customerName", user.customerName);
    dataArray.append("phone", user.phone);
    dataArray.append("email", user.email);
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("avatar", Files[i]);
    }
    var token = sessionStorage.getItem("token");
    axios
      .put(
        "https://api.freedomcars.co.in/v1/app/customer/editapp-customerInfo",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    var _id = sessionStorage.getItem("UserId");
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/customer/customerappgetdetails",
        { _id },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.customerResult);
      });
  };

  return (
    <div>
      <Header />
      <main class="main">
        <div class="container">
          <div class="row row--grid">
            <div class="col-12">
              <div class="main__title main__title--page">
                <h5>My account</h5>
              </div>
            </div>
          </div>
          <div class="row row--grid">
            <div class="col-12 col-lg-6">
              <form
                class="offer"
                method="post"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div class="row">
                  <div class="col-12">
                    <h4 class="sign__title">Profile Details</h4>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="sign__group">
                      <label>Name</label>
                      <input
                        type="text"
                        value={user.customerName}
                        name="customerName"
                        placeholder="Enter Name"
                        required
                        className="form-control"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="sign__group">
                      <label>Mobile</label>
                      <input
                        type="text"
                        maxlength="10"
                        pattern="\d{10}"
                        value={user.phone}
                        name="phone"
                        placeholder="Enter Mobile"
                        required
                        className="form-control "
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="sign__group">
                      <label>Email</label>
                      <input
                        type="text"
                        value={user.email}
                        required
                        placeholder="Enter Email Id"
                        name="email"
                        className="form-control"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="sign__group">
                      <label for="image">Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        multiple
                        onChange={changeHandler}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-primary mt-5"
                    style={{ float: "right", color: "white" }}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>

            <div class="col-12 col-lg-6">
              <form
                class="offer"
                method="post"
                onSubmit={(e) => {
                  handleSubmit1(e);
                }}
              >
                <div class="row">
                  <div class="col-12">
                    <h4 class="sign__title">Change Password</h4>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="">
                      <label>Old password</label>
                      <div class="sign__group">
                        <input
                          name="password"
                          class="form-control"
                          value={form.password}
                          type={showPassword ? 'text' : 'password'}
                          required
                          minLength={6}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                        />
                        <div className="textps">
                          <i
                            className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                            aria-hidden="true"
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                      </div>
                      {/* <input
                        type="text"
                        value={form.password}
                        placeholder="Enter new password"
                        className="form-control"
                        name="password"
                        minLength={6}
                        // pattern="[A-Za-z\d]{6,}$"
                        required
                        onChange={(e) => {
                          handleChange1(e);
                        }}
                      /> */}
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="">
                      <label>New password</label>
                      <div class="sign__group">
                        <input
                          name="newpassword"
                          class="form-control"
                          value={form.newpassword}
                          type={showPassword2 ? 'text' : 'password'}
                          required
                          minLength={6}
                          placeholder="Enter New Password"
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                        />
                        <div className="textps">
                          <i
                            className={`fa ${showPassword2 ? 'fa-eye' : 'fa-eye-slash'}`}
                            aria-hidden="true"
                            onClick={togglePasswordVisibility2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12 col-xl-6">
                    <div class="">
                      <label>Confirm new password</label>
                      <div class="sign__group">
                        <input
                          name="confirmpassword"
                          class="form-control"
                          value={form.confirmpassword}
                          type={showPassword3 ? 'text' : 'password'}
                          required
                          minLength={6}
                          placeholder="Enter Confirm Password"
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                        />
                        <div className="textps">
                          <i
                            className={`fa ${showPassword3 ? 'fa-eye' : 'fa-eye-slash'}`}
                            aria-hidden="true"
                            onClick={togglePasswordVisibility3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    typpe="submit"
                    className="btn btn-primary mt-5"
                    style={{ float: "right", color: "white" }}
                  >
                    Change
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Profile;
