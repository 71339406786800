import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";

function Copun() {
  const [cop, setcop] = useState([]);

  useEffect(() => {
    GeTCOP();
  }, []);

  const GeTCOP = () => {
    var token = sessionStorage.getItem("token");
    console.log(token);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/booking/getallactivecoupons",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            console.log(res.data);
            setcop(res.data.activeCoupons);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  return (
    <div>
      <Header />

      <main class="main">
        <Container class="mt-5">
          <div class="col-12 mt-5">
            <div class="main__title main__title--page">
              <h1>Coupons</h1>
            </div>
          </div>
          <section class="row row--grid">
          {cop.length >= 1 ? ( 
            <Row>
              {cop.map((data, key) => {
                return (
                  <>
                    <div class="container-fuild mt-2 ">
                      <div class="d-flex justify-content-center row">
                        <Col md={6} className="mt-2 ">
                          <div
                            class="col-md-12 card"
                            style={{ background: "#FFCEA3" }}
                          >
                            <div class="coupon p-3 ">
                              <div class="row no-gutters">
                                <div class="col-md-4 border-right">
                                  <div class="d-flex flex-column align-items-center">
                                    <img src="https://i.imgur.com/XwBlVpS.png" />
                                    <span class="d-block">Freedom Cars</span>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div>
                                    <h6
                                      className="text-center text-uppercase"
                                      style={{ color: "black" }}
                                    >
                                      {data.title}
                                    </h6>
                                    <div class="d-flex flex-row justify-content-end off">
                                      {data.percentDiscount == "undefined" ||
                                      data.percentDiscount == "" ? (<>
                                      <h1>{data.amount}</h1>  <p>Rs</p>/-</>
                                      ) : (
                                        <h1>{data.percentDiscount}%</h1>
                                      )}
                                      <span>OFF</span>
                                    </div>
                                    <div class="d-flex flex-row justify-content-between off px-3 p-3">
                                      <span>Promo code:</span>
                                      <div class="badge  text-bg-danger">
                                  {data.coupon_code}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </>
                );
              })}
            </Row>
               
               ) : (
                <div style={{height:"350px"}}>
                 <center style={{marginTop:"200px"}}>
                   <h4>No Data Found</h4>
                 </center></div>
               )}
          </section>
        </Container>
      </main>
      <Footer />
    </div>
  );
}

export default Copun;
