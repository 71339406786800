import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import car2 from "../img/cars/car5.jfif";
import car3 from "../img/cars/car3.jpg";
import car4 from "../img/cars/car6.jpg";
import car8 from "../img/cars/car8.jpg";
import car6 from "../img/cars/car6.jpg";

import car7 from "../img/cars/car7.jpg";
import car11 from "../img/cars/car13.jpg";
import { Link, NavLink } from "react-router-dom";
import "./Common.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import Carousel from "react-bootstrap/Carousel";

function Home() {
  useEffect(() => {
    getcars();
  }, []);

  const [car, setcar] = useState([]);
  console.log(car);

  const [banner, setbanner] = useState([]);
  console.log(car);

  const getcars = () => {
    axios
      .post("https://api.freedomcars.co.in/v1/app/carmodel/getwebhomeitems", {})
      .then((res) => {
        setcar(res.data.featuredCarResult);
        setbanner(res.data.actBannerResult);
      });
  };

  {
    /* {banner.map((data, i) => (
                <>    </>
              ))}
               src={"https://api.freedomcars.co.in/" + data.bannerImage} */
  }

  const navigate = useNavigate();

  const toComponentd = (data, i) => {
    navigate("/cardetails", { state: data });
  };

  return (
    <div>
      <Header />

      <main style={{overflowX:"hidden"}} class="main">
        {/* <div className="row">
          {banner.length >= 1 ? (
            <>
              <Carousel fade dots="false">
                {banner.map((data, i) => {
                  return (
                    <Carousel.Item key={data.id}>
                      <div
                        key={i}
                        className="d-block w-100"
                        style={{
                          backgroundImage: `url(${
                            "https://api.freedomcars.co.in/" + data.bannerImage
                          })`,
                          backgroundRepeat: "no-repeat",
                          height: "500px",
                        }}
                      >
                        <div class="container">
                          <div class="banner-content text-center">
                            <h1
                              class="banner-title capitalize wow fadeInLeft"
                              data-wow-delay="400ms"
                              data-wow-duration="3000ms"
                              data-aos="fade-right"
                              style={{ color: "white" }}
                            >
                              Welcome to Freedom Cars.
                            </h1>
                            <div
                              class="desc mb-35 wow wow fadeInRight"
                              data-wow-delay="900ms"
                              data-wow-duration="3000ms"
                              data-aos="fade-left"
                              style={{ color: "white" }}
                            >
                              Our reliable fleet which operate with us will make
                              your journey a breeze. Whether you’re travelling
                              to
                              <br></br>
                              the airport or out of station, you call on the
                              right car & bike to suit your needs.
                            </div>
                            <div
                              class="banner-btn wow fadeInUp"
                              data-wow-delay="1500ms"
                              data-wow-duration="2000ms"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </>
          ) : (
            <div
              id="carouselExampleFade"
              className="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    style={{ height: "500px" }}
                    src={car3}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 data-aos="fade-right" data-aos-duration="1500">
                      Welcome to Freedom Cars.
                    </h5>
                    <p data-aos="fade-left" data-aos-duration="1500">
                      Our reliable fleet which operate with us will make your
                      journey a breeze. Whether you’re travelling to the airport
                      or out of station, you call on the right car & bike to
                      suit your needs.
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    style={{ height: "500px" }}
                    src={car4}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 data-aos="fade-right" data-aos-duration="1500">
                      Welcome to Freedom Cars.
                    </h5>
                    <p data-aos="fade-left" data-aos-duration="1500">
                      Our reliable fleet which operate with us will make your
                      journey a breeze. Whether you’re travelling to the airport
                      <br></br>
                      or out of station, you call on the right car & bike to
                      suit your needs.
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-target="#carouselExampleFade"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-target="#carouselExampleFade"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </button>
            </div>
          )}
        </div> */}
        <div className="row">
          <Carousel fade>
            <Carousel.Item>
              <img
                className="d-block w-100"
                style={{ height: "600px" }}
                src={car11}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3 className="fnt1 ">Welcome to Freedom Cars.</h3>
                <h5>About ? </h5>

                <p className="fnt" style={{ paddingBottom: "170px" }}>
                  {" "}
                  Freedom Cars is one of the fastest growing shared mobility
                  platform, providing both self-drive car & bikes rental and
                  services to its customers. Freedom Cars currently operates a
                  fleet of 30+ cars in 2 cities within India and still counting.
                  The Hyderabad based self-drive car rental company is founded
                  by P.Sasi Kumar & V.Prasad. With the mission of solving for
                  diverse mobility needs, Freedom Cars officially began
                  operations on 21st December 2021 with a fleet of 3 cars in the
                  Hyderabad region.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                style={{ height: "600px" }}
                src={car8}
                alt="Second slide"
              />

              <Carousel.Caption>
                <h3 className="fnt1">Welcome to Freedom Cars.</h3>
                <h5>why us ?</h5>
                <br></br>
                <p className="fnt" style={{ paddingBottom: "320px" }}>
                  {" "}
                  We rental the comfortline cars to premium end cars in pocket
                  friendly prices with unlimited km & with less documentation.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={car3}
                style={{ height: "600px" }}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h3 className="fnt1">Welcome to Freedom Cars.</h3>
                <br></br>
                <h5>Our services ?</h5>
                <p className="fnt" style={{ paddingBottom: "10px" }}>
                  We have services like door step drop & pickup and our cars are
                  always been well serviced to satisfy customers and also for
                  customer safety drive.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* <div
              id="carouselExampleFade"
              className="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    style={{ height: "500px" }}
                    src={car3}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block mb-5">
                    <h5 data-aos="fade-right" data-aos-duration="1500">
                      Welcome to Freedom Cars.
                    </h5>
                    <h5>About ? </h5>
                      <br></br>
                      <b>
                      Freedom Cars is one of the fastest growing shared mobility
                      platform, providing both self-drive car & bikes rental and
                      services to its customers. Freedom Cars currently operates
                      a fleet of 30+ cars in 2 cities within India and still
                      counting. The Hyderabad based self-drive car rental
                      company is founded by P.Sasi Kumar & V.Prasad. With the
                      mission of solving for diverse mobility needs, Freedom
                      Cars officially began operations on 21st December 2021
                      with a fleet of 3 cars in the Hyderabad region.</b>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    style={{ height: "500px" }}
                    src={car4}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block mb-5 mb-5">
                    <h5 data-aos="fade-right" data-aos-duration="1500">
                      Welcome to Freedom Cars.
                    </h5>
                    <h5>why us ?</h5>
                      <br></br>
                      <b>We rental the comfortline cars to premium end cars in
                      pocket friendly prices with unlimited km & with less
                      documentation.</b>
                  </div>
                </div>

                <div className="carousel-item">
                  <img
                    style={{ height: "500px" }}
                    src={car11}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block mb-5 mb-5">
                    <h5 data-aos="fade-right" data-aos-duration="1500">
                      Welcome to Freedom Cars.
                    </h5>
                    <h5>Our services</h5>
                      <br></br>
                     <b> We have services like door step drop & pickup and our cars
                      are always been well serviced to satisfy customers and
                      also for customer safety drive</b>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-target="#carouselExampleFade"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-target="#carouselExampleFade"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </button>
            </div> */}

        {/* 
        <div className="row">
          <Carousel fade dots="false">
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${car3})`,
                  height: "500px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100vw",
                  height: "500px",
                }}
              >
                <div class="container">
                  <div class="banner-content text-center">
                    <h1
                      class="banner-title capitalize wow fadeInLeft"
                      data-wow-delay="400ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-right"
                      style={{ color: "white" }}
                    >
                      Welcome to Freedom Cars.
                    </h1>
                    <div
                      class="desc mb-35 wow wow fadeInRight"
                      data-wow-delay="900ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-left"
                      style={{ color: "white" }}
                    >
                     
                      <h5>About ? </h5>
                      <br></br>
                      <b>
                      Freedom Cars is one of the fastest growing shared mobility
                      platform, providing both self-drive car & bikes rental and
                      services to its customers. Freedom Cars currently operates
                      a fleet of 30+ cars in 2 cities within India and still
                      counting. The Hyderabad based self-drive car rental
                      company is founded by P.Sasi Kumar & V.Prasad. With the
                      mission of solving for diverse mobility needs, Freedom
                      Cars officially began operations on 21st December 2021
                      with a fleet of 3 cars in the Hyderabad region.</b>
                    </div>
                    <div
                      class="banner-btn wow fadeInUp"
                      data-wow-delay="1500ms"
                      data-wow-duration="2000ms"
                    ></div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${car6})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100vw",
                  height: "500px",
                }}
              >
                <div class="container">
                  <div class="banner-content text-center">
                    <h1
                      class="banner-title capitalize wow fadeInLeft"
                      data-wow-delay="400ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-right"
                      style={{ color: "white" }}
                    >
                      Welcome to Freedom Cars.
                    </h1>
                    <div
                      class="desc mb-35 wow wow fadeInRight"
                      data-wow-delay="900ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-left"
                      style={{ color: "white" }}
                    >
                   
                      <h5>why us ?</h5>
                      <br></br>
                      We rental the comfortline cars to premium end cars in
                      pocket friendly prices with unlimited km & with less
                      documentation.
                    </div>
                    <div
                      class="banner-btn wow fadeInUp"
                      data-wow-delay="1500ms"
                      data-wow-duration="2000ms"
                    ></div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${car11})`,
                 
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100vw",
                  height: "500px",
                }}
              >
                <div class="container">
                  <div class="banner-content text-center">
                    <h1
                      class="banner-title capitalize wow fadeInLeft"
                      data-wow-delay="400ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-right"
                      style={{ color: "white" }}
                    >
                      Welcome to Freedom Cars.
                    </h1>
                    <div
                      class="desc mb-35 wow wow fadeInRight"
                      data-wow-delay="900ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-left"
                      style={{ color: "white" }}
                    >
                     
                      <h5>Our services</h5>
                      <br></br>
                      We have services like door step drop & pickup and our cars
                      are always been well serviced to satisfy customers and
                      also for customer safety drive
                    </div>
                    <div
                      class="banner-btn wow fadeInUp"
                      data-wow-delay="1500ms"
                      data-wow-duration="2000ms"
                    ></div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div> */}

        <div class="container">
          <section class="row row--grid">
            <div class="col-12">
              <div class="main__title main__title--first">
                <h2>Featured cars</h2>
                <NavLink to="/car">
                  <a class="main__link">
                    View more
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                    </svg>
                  </a>
                </NavLink>
              </div>
            </div>

            {car.map((data, key) => (
              <div class="col-12 col-md-6 col-xl-3">
                <div class="car">
                  {" "}
                  <div class="splide--card car__slider">
                    <ul class="splide__list">
                      <li class="splide__slide">
                        <img
                          src={"https://api.freedomcars.co.in/" + data.carImage[0]}
                          height="300px"
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toComponentd(data, key);
                          }}
                          key={key}
                        />
                      </li>
                    </ul>
                  </div>
                  <div class="car__title">
                    <h3 class="car__name">
                      <a>{data.carModelName}</a>
                    </h3>
                    <span class="car__year">{data.carMakeYear}</span>
                  </div>
                  <ul class="car__list">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z" />
                      </svg>
                      {data.seatCount == null ? (
                        <span>0 People</span>
                      ) : (
                        <span>{data.seatCount} People</span>
                      )}
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20.54,6.29,19,4.75,17.59,3.34a1,1,0,0,0-1.42,1.42l1,1-.83,2.49a3,3,0,0,0,.73,3.07l2.95,3V19a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H14V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V16h1a1,1,0,0,1,1,1v2a3,3,0,0,0,6,0V9.83A5,5,0,0,0,20.54,6.29ZM12,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12h8Zm0-9H4V5A1,1,0,0,1,5,4h6a1,1,0,0,1,1,1Zm8,1.42L18.46,9.88a1,1,0,0,1-.24-1l.51-1.54.39.4A3,3,0,0,1,20,9.83Z" />
                      </svg>
                      <span>Petrol/Diesel</span>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19.088,4.95453c-.00732-.00781-.00952-.01819-.01715-.02582s-.01819-.00995-.02606-.01733a9.97886,9.97886,0,0,0-14.08948,0c-.00787.00738-.01837.00964-.02606.01733s-.00983.018-.01715.02582a10,10,0,1,0,14.1759,0ZM12,20a7.9847,7.9847,0,0,1-6.235-3H9.78027a2.9636,2.9636,0,0,0,4.43946,0h4.01532A7.9847,7.9847,0,0,1,12,20Zm-1-5a1,1,0,1,1,1,1A1.001,1.001,0,0,1,11,15Zm8.41022.00208L19.3999,15H15a2.99507,2.99507,0,0,0-2-2.81573V9a1,1,0,0,0-2,0v3.18427A2.99507,2.99507,0,0,0,9,15H4.6001l-.01032.00208A7.93083,7.93083,0,0,1,4.06946,13H5a1,1,0,0,0,0-2H4.06946A7.95128,7.95128,0,0,1,5.68854,7.10211l.65472.65473A.99989.99989,0,1,0,7.75732,6.34277l-.65466-.65466A7.95231,7.95231,0,0,1,11,4.06946V5a1,1,0,0,0,2,0V4.06946a7.95231,7.95231,0,0,1,3.89734,1.61865l-.65466.65466a.99989.99989,0,1,0,1.41406,1.41407l.65472-.65473A7.95128,7.95128,0,0,1,19.93054,11H19a1,1,0,0,0,0,2h.93054A7.93083,7.93083,0,0,1,19.41022,15.00208Z" />
                      </svg>
                      <span>{data.carFeatures[0].length> 10 ?data.carFeatures[0].substring(0, 10) + "..." :data.carFeatures[0]}</span>

                      {/* <span>
                        {" "}
                        {data.carFeatures[0].length > 10
                          ? data.carFeatures[0].slice(0, 10) + "..."
                          : data.carFeatures[0]}
                      </span> */}
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12,12a1,1,0,1,0,1,1A1,1,0,0,0,12,12Zm9.71-2.36a0,0,0,0,1,0,0,10,10,0,0,0-19.4,0,0,0,0,0,1,0,0,9.75,9.75,0,0,0,0,4.72,0,0,0,0,1,0,0A10,10,0,0,0,9.61,21.7h0a9.67,9.67,0,0,0,4.7,0h0a10,10,0,0,0,7.31-7.31,0,0,0,0,1,0,0,9.75,9.75,0,0,0,0-4.72ZM12,4a8,8,0,0,1,7.41,5H4.59A8,8,0,0,1,12,4ZM4,12a8.26,8.26,0,0,1,.07-1H6v2H4.07A8.26,8.26,0,0,1,4,12Zm5,7.41A8,8,0,0,1,4.59,15H7a2,2,0,0,1,2,2Zm4,.52A8.26,8.26,0,0,1,12,20a8.26,8.26,0,0,1-1-.07V18h2ZM13.14,16H10.86A4,4,0,0,0,8,13.14V11h8v2.14A4,4,0,0,0,13.14,16ZM15,19.41V17a2,2,0,0,1,2-2h2.41A8,8,0,0,1,15,19.41ZM19.93,13H18V11h1.93A8.26,8.26,0,0,1,20,12,8.26,8.26,0,0,1,19.93,13Z" />
                      </svg>
                      <span>{data.vehicleType}</span>
                    </li>
                  </ul>
                  <div class="car__footer">
                    <span class="car__price">
                      {data.carprice[0].onedayPrice}₹<sub>/ day</sub>
                    </span>

                    <Button
                      style={{ cursor: "pointer", background: "#015A65" }}
                      className="btn"
                      onClick={() => {
                        toComponentd(data, key);
                      }}
                      key={key}
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </section>

          <section class="row row--grid">
            <div class="col-12">
              <div class="main__title">
                <h2>Get started with 4 simple steps</h2>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
              <div class="step1">
                <span class="step1__icon step1__icon--pink">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M21,10.5H20v-1a1,1,0,0,0-2,0v1H17a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0v-1h1a1,1,0,0,0,0-2Zm-7.7,1.72A4.92,4.92,0,0,0,15,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,2,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,13.3,12.22ZM10,11.5a3,3,0,1,1,3-3A3,3,0,0,1,10,11.5Z" />
                  </svg>
                </span>
                <h3 class="step1__title">Create a profile</h3>
                <p class="step1__text">
                  Freedom Cars is one of the fastest growing shared mobility
                  platform, providing both self-drive car & bikes rental and
                  services to its customers. <br />
                  <NavLink to="/Signup">
                    <a class="main__link">
                      Get started
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                      </svg>
                    </a>
                  </NavLink>
                </p>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
              <div class="step1">
                <span class="step1__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M6.62,13.08a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33A.84.84,0,0,0,8,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,6.62,13.08Zm13.14-4L18.4,5.05a3,3,0,0,0-2.84-2H8.44A3,3,0,0,0,5.6,5.05L4.24,9.11A3,3,0,0,0,2,12v4a3,3,0,0,0,2,2.82V20a1,1,0,0,0,2,0V19H18v1a1,1,0,0,0,2,0V18.82A3,3,0,0,0,22,16V12A3,3,0,0,0,19.76,9.11ZM7.49,5.68A1,1,0,0,1,8.44,5h7.12a1,1,0,0,1,1,.68L17.61,9H6.39ZM20,16a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Zm-3.38-2.92a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3.9.9,0,0,0,.54-.54A.84.84,0,0,0,18,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,16.62,13.08ZM13,13H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
                  </svg>
                </span>
                <h3 class="step1__title">Tell us what car you want</h3>
                <p class="step1__text">
                  The Hyderabad based self-drive car rental company is founded
                  by P.Sasi Kumar & V.Prasad. With the mission of solving for
                  diverse mobility needs
                </p>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
              <div class="step1">
                <span class="step1__icon step1__icon--green">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M13.3,12.22A4.92,4.92,0,0,0,15,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,2,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,13.3,12.22ZM10,11.5a3,3,0,1,1,3-3A3,3,0,0,1,10,11.5ZM21.71,9.13a1,1,0,0,0-1.42,0l-2,2-.62-.63a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l1.34,1.34a1,1,0,0,0,1.41,0l2.67-2.67A1,1,0,0,0,21.71,9.13Z" />
                  </svg>
                </span>
                <h3 class="step1__title">Match with seller</h3>
                <p class="step1__text">
                  We rental the comfortline cars to premium end cars in pocket
                  friendly prices with unlimited km & with less documentation.
                </p>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
              <div class="step1">
                <span class="step1__icon step1__icon--purple">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20.71,16.71l-2.42-2.42a1,1,0,0,0-1.42,0l-3.58,3.58a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1h2.42a1,1,0,0,0,.71-.29l3.58-3.58A1,1,0,0,0,20.71,16.71ZM16,20H15V19l2.58-2.58,1,1Zm-6,0H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h5V7a3,3,0,0,0,3,3h3v1a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0L12.06,2H6A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3h4a1,1,0,0,0,0-2ZM13,5.41,15.59,8H14a1,1,0,0,1-1-1ZM8,14h6a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2Zm0-4H9A1,1,0,0,0,9,8H8a1,1,0,0,0,0,2Zm2,6H8a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
                  </svg>
                </span>
                <h3 class="step1__title">Make a deal</h3>
                <p class="step1__text">
                  We have services like door step drop & pickup and our cars are
                  always been well serviced to satisfy customers and also for
                  customer safety drive.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
