import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import logo from "../img/freedom4.png";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Forgetpassword() {
    const [user, setUser] = useState({ email: "", password: "" });

    const handleChange = (e) => {
      let newadmin = { ...user };
      newadmin[e.target.name] = e.target.value;
      setUser(newadmin);
    };
  
    const usersign = (e) => {
      e.preventDefault();
      signin();
    };
  
    const location = useLocation();
  
    const cars1 = location.state;
  
    const navigate = useNavigate();
    const signin = () => {
        const dataArray = new FormData();
        dataArray.append("emailOtp", user.emailOtp);
        dataArray.append("emailId",  sessionStorage.getItem("email"));
      axios
        .post(
            'https://api.freedomcars.co.in/v1/app/customer/otp-check',
            dataArray
        )
        .then(
          (res) => {
            toast(res.data.message);
            setTimeout(() => {
              navigate("/ChangePassword");
              toast.dismiss();
            }, 2000);
          },
          (error) => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message);
            }
          }
        );
    };

    
  return (
    <div>
      <main class="main main--sign" data-bg="img/bg/bg.png">
        <div class="sign">
          <div class="sign__content">
            <form  class="sign__form"
              onSubmit={(e) => {
                usersign(e);
              }}
              method="post">
              <a class="sign__logo">
                {/* <img src="img/logo.svg" alt="" /> */}
                <img src={logo} width="200px"></img>
              </a>

              <div class="sign__group">
                <input type="text"
                  required
                  class="sign__input"
                  placeholder="Otp"
                  name="emailOtp"
                  value={user.emailOtp}
                  onChange={(e) => {
                    handleChange(e);
                  }} />
              </div>
{/* 
              <div class="sign__group sign__group--checkbox">
                <input id="remember" name="remember" type="checkbox" />
                <label for="remember">
                  I agree to the <a >Privacy Policy</a>
                </label>
              </div> */}

              <button class="sign__btn" type="submit">
                <span>Send</span>
              </button>

              <span class="sign__text">
                We will send a password to your Email
              </span>
            </form>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}

export default Forgetpassword;
