import React, {useState, useEffect} from 'react'

import Header from "./Header";
import Footer from "./Footer";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Termsconditions() {

  
  const [forms, setforms] = useState([]);

  const getData = () => {
    axios
      .post("https://api.freedomcars.co.in/v1/app/general/getterms_conditions", {})
      .then((res) => {
        setforms(res.data.terms_conditions);
      });
  };

  useEffect(()=>{
    getData()
  }, [])

  return (
    <div>
    <Header />{" "}
    <div>
      <main class="main">
        <Container
          class="mt-5 mb-5"
          // style={{ marginTop: "150px", marginBottom: "100px" }}
        >
          <div class="col-12 mt-5">
            <div class="main__title main__title--page">
              <div class="details">
                <h5>Terms & conditions</h5>
                <div class=" splide--details details__slider">
                <div
                        className="mt-5"
                        dangerouslySetInnerHTML={{
                          __html: forms.terms_conditions,
                        }}
                      ></div>


                  {/* <p>
                    1.Customer should submit the proofs of Adhar & Driving
                    licence(orginal)<br></br>
                    2.Security Deposit (Should be submit any one from the
                    below)<br></br>
                    a. 3.Deposit amount starts from Rs.20000/-depending upon
                    selected car b. Bike+ RC orginal (2013 and above model)
                    <br></br>
                    c. Passport<br></br>
                    d. Laptop (2018 model & above<br></br>
                    4.If there is any damage on the Car/ Vehicle, repair or
                    fixing to original condition is to be done by the
                    customer.<br></br>
                    5.If the vehicle is damaged, firstly the information
                    should be passed to the company and can get it fixed or
                    repaired at the authorized showroom only and can return
                    the car in original condition<br></br>
                    6.Rental shall be paid till the car is returned from
                    garage in a reusable condition.<br></br>
                    7.Any fixture or repair or painting done from unauthorized
                    garage shall strictly not be accepted and car will be sent
                    to authorize service center to get if fixed and rental and
                    other incidental charges shall be charged accordingly.
                    <br></br>
                    8.Any Extension or delay should be informed in prior and
                    can only extend with the permission of the company and any
                    extension without permission will lead to termination of
                    Deposit as it may cause the inconvenience to the next
                    customer.<br></br>
                    9.A Maximum speed limit of 100kmph is allowed on cars and
                    exceeding shall void the insurance claim benefit and will
                    attract a penalty of Rs. 500<br></br>
                    per Violation.<br></br>
                    10.If the car is return in a filth condition or with any
                    odors that might cause inconvenience to next customer will
                    lead to a charge for Car wash or<br></br>
                    Interior Cleaning.<br></br>
                    11. Any Traffic Violations or Challans made by the
                    customer shall pay within the 7 days of intimation from
                    the company and will lead to Rs. 500 Penalty per day after
                    7 days<br></br>
                    12.The Customer should inform about the route or places he
                    would be travelling and driving to a different route or
                    area will lead to termination of deposit and shall lead to
                    legal escalations.<br></br>
                    13.The car should not be misused or used for any illegal
                    activities and any escalations shall be responsible by the
                    customer and will pay all the incidental charges occurred
                    to overcome the issue.<br></br>
                    14.Drunken Driving is strictly prohibited while driving
                    the car and will be penalized for Rs.<br></br>
                    50,000 if found.<br></br>
                    15.All Rentals should be paid in advance and any delay in
                    payment shall attract a penalty of 10% per day.<br></br>
                    16.Cancellation Policy - 100% Refund will be done if
                    cancelled 48 hours or before from starting time of
                    booking. 50% shall be refunded if cancelled 24 hours
                    before the booking starts and<br></br>
                    No Refund shall be made if cancelled in less than<br></br>
                    5 hours from the starting time of booking.<br></br>
                    17.Security Refundable Deposit shall be refunded in same
                    payment mode as customers did after vehicle is returned.
                    <br></br>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
      <ToastContainer />
    </div>
    <Footer />
  </div>
  )
}

export default Termsconditions