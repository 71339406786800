import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import PropTypes from "prop-types";
// import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../img/freedom4.png";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Offcanvas from "react-bootstrap/Offcanvas";

// function Example() {
//     return (
//         <>
//             {['start', 'end', 'top', 'bottom'].map((placement, idx) => (
//                 <OffCanvasExample key={idx} placement={placement} name={placement} />
//             ))}
//         </>
//     );
// }

function Header({ direction, ...args }) {
  const navigate = useNavigate();
  const handlesession = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <header class="header">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="header__content">
                <div class="header__logo">
                  <NavLink to="/">
                    <a>
                      <img src={logo} alt="" />
                    </a>
                  </NavLink>
                </div>

                <div class="header__menu">
                  <ul class="header__nav">
                    <li class="header__nav-item">
                      <NavLink to="/" activeClassName="active-link">
                        <a
                          class="header__nav-link" 
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Home{" "}
                        </a>
                      </NavLink>
                    </li>
                    <li class="header__nav-item">
                      <NavLink to="/car" activeClassName="active-link">
                        <a
                          class="header__nav-link"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Explore cars
                        </a>
                      </NavLink>
                    </li>
                    <li class="header__nav-item" activeClassName="active-link">
                      <NavLink to="/Contact">
                        <a class="header__nav-link">Help center</a>
                      </NavLink>
                    </li>
                    {/* <li class="header__nav-item">
									<a class="header__nav-link" href="#" role="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg></a>
								</li> */}
                  </ul>
                </div>

                <div class="header__actions">
                  {/* <div class="header__phone">
								<a href="tel:88002345678">8 800 234 56 78</a>
								<span>Mom - Sat: 9:00 am - 6:00 pm</span>
							</div> */}
                  {/* 
							<div class="header__action">
								<a class="header__action-btn" href="cars.html">
									<span>Explore cars</span>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.62,13.08a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33A.84.84,0,0,0,8,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,6.62,13.08Zm13.14-4L18.4,5.05a3,3,0,0,0-2.84-2H8.44A3,3,0,0,0,5.6,5.05L4.24,9.11A3,3,0,0,0,2,12v4a3,3,0,0,0,2,2.82V20a1,1,0,0,0,2,0V19H18v1a1,1,0,0,0,2,0V18.82A3,3,0,0,0,22,16V12A3,3,0,0,0,19.76,9.11ZM7.49,5.68A1,1,0,0,1,8.44,5h7.12a1,1,0,0,1,1,.68L17.61,9H6.39ZM20,16a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Zm-3.38-2.92a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3.9.9,0,0,0,.54-.54A.84.84,0,0,0,18,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,16.62,13.08ZM13,13H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"/></svg>
								</a>
							</div> */}

                  <div class="header__action">
                    <a
                      class="header__profile-btn"
                      href="#"
                      role="button"
                      id="dropdownMenuProfile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" />
                      </svg>
                    </a>

                    <ul
                      class="dropdown-menu header__profile-menu"
                      aria-labelledby="dropdownMenuProfile"
                    >
                      {sessionStorage.getItem("UserId") != "" &&
                      sessionStorage.getItem("UserId") ? (
                        ""
                      ) : (
                        <li className="m-2">
                          <NavLink style={{ fontSize: "15px" }} to="/login">
                            <i
                              class="fa fa-sign-in mr-2"
                              aria-hidden="true"
                            ></i>
                            Login
                          </NavLink>
                        </li>
                      )}

                      {sessionStorage.getItem("UserId") != "" &&
                      sessionStorage.getItem("UserId") ? (
                        <>
                          <li className="m-2">
                            {" "}
                            <NavLink style={{ fontSize: "15px" }} to="/Profile">
                              <i class="fa fa-user mr-2" aria-hidden="true"></i>{" "}
                              Profile{" "}
                            </NavLink>
                          </li>
                          {/* <li className="m-2">
                            
                            <NavLink style={{ fontSize: "15px" }} to="/Wallet">
                          
                              <i
                                class="fa fa-shopping-bag mr-2"
                                aria-hidden="true"
                              ></i>
                              My Wallet
                            </NavLink>
                          </li> */}
                          <li className="m-2">
                            <NavLink
                              style={{ fontSize: "15px" }}
                              to="/DrivePoints"
                            >
                              {" "}
                              <i
                                class="fa fa-car mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Drive Points
                            </NavLink>
                          </li>
                          <li className="m-2">
                            {" "}
                            <NavLink
                              style={{ fontSize: "15px" }}
                              to="/favourites"
                            >
                              {" "}
                              <i
                                class="fa fa-heart mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Favourites{" "}
                            </NavLink>
                          </li>
                          <li className="m-2">
                            {" "}
                            <NavLink
                              style={{ fontSize: "15px" }}
                              to="/Mybookings"
                            >
                              {" "}
                              <i
                                class="fa fa-bookmark mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              My Bookings{" "}
                            </NavLink>
                          </li>

                          <li className="m-2">
                            <NavLink to="/MybookingHistory">
                              <i
                                class="fa fa-history mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Bookings History
                            </NavLink>
                          </li>

                          {/* <li className="m-2">
                            <NavLink to="/Coupon">
                              <i class="fa fa-tag mr-2" aria-hidden="true"></i>{" "}
                              Coupons
                            </NavLink>
                          </li> */}

                          <li
                            className="m-2 text-danger"
                            onClick={handlesession}
                          >
                            {" "}
                            <a style={{ fontSize: "15px", cursor: "pointer" }}>
                              {" "}
                              <i
                                class="fa fa-sign-out mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Log Out
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>

                <button onClick={handleShow} class="header__btn" type="button">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <Offcanvas
                  style={{ width: "60%" }}
                  show={show}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <NavLink to="/Home">
                        <a>
                          <img style={{width:"120px"}} src={logo} alt="" />
                        </a>
                      </NavLink>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <ul class="header__nav">
                      <li class="header__nav-item">
                        <NavLink to="/">
                          <a
                            class="header__nav-link"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Home
                          </a>
                        </NavLink>
                      </li>
                      <li class="header__nav-item">
                        <NavLink to="/car">
                          <a
                            class="header__nav-link"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Explore cars
                          </a>
                        </NavLink>
                      </li>
                      <li class="header__nav-item">
                        <NavLink to="/Contact">
                          <a href="faq.html" class="header__nav-link">
                            Help center
                          </a>
                        </NavLink>
                      </li>
                    </ul>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
// seatCount
