import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Carousel from "react-bootstrap/Carousel";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";

import { Label, InputGroup } from "reactstrap";

// import Moment from 'Moment'

function CarBooking() {
  const Razorpay = useRazorpay();

  const navigate = useNavigate();

  const location = useLocation();

  const cars1s = location.state;

  const cars1 = location.state.carImage;

  const [form, setform] = useState([]);
  console.log(form);

  const handlechange = (e) => {
    const myform = { ...form };
    myform[e.target.name] = e.target.value;
    setform(myform);
  };

  const handleChange12 = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
    getcarprice(e.target.value);
    myUser.paymentStatus = "";
    myUser.fromDate = "";
    myUser.toDate = "";
  };
  const [Kms, setKms] = useState([]);

  const getcarprice = (val) => {
    var token = sessionStorage.getItem("token");
    console.log(token);

    let objData = { carId: location.state._id, hrs: val };

    axios
      .post("https://api.freedomcars.co.in/v1/app/booking/getprices", objData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        (res) => {
          if (res.status === 200) {
            console.log(res.data);
            setKms(res.data.data);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const handlechangeprice = (e) => {
    var res = e.target.value.split("/");

    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;

    myUser["price"] = res[1];
    myUser["totalprice"] = res[1];

    myUser["carPriceId"] = res[0];
    setform(myUser);
    myUser.paymentStatus = "";
    myUser.fromDate = "";
    myUser.toDate = "";
  };

  const [dds, setdds] = useState([]);

  const [gst, setgst] = useState([]);
  const [getway, setgetway] = useState([]);
  const [getway1, setgetway1] = useState([]);
  const [total, settotal] = useState([]);
  const [sendprice, setsendprice] = useState([]);

  const handleChangedates = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);

    const tomorrow = new Date(e.target.value);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setdds(tomorrow.toISOString().split("T")[0]);
  };

  const [count, setcount] = useState([]);
  console.log(count)

  const [pot, setpot] = useState([]);

  // const handleChangedate = (e) => {
  //   let myUser = { ...form };
  //   myUser[e.target.name] = e.target.value;
  //   setform(myUser);
  //   const fdate = new Date(form.fromDate).getTime();
  //   console.log(fdate);
  //   const totaldays = new Date(e.target.value).getTime() - fdate;

  //   const countdays = totaldays / (1000 * 60 * 60 * 24);

  //   const user1 = countdays * form.price;
  //   console.log(user1);

  //   setpot(user1);

  //   setcount(countdays);

  //   const Gst = user1 * 0.01;
  //   const Getway = user1 * 0.02;
  //   const Getway1 = parseInt(totals) * 0.02;

  //   const totalamount = (Gst).toFixed() + (Getway).toFixed();

  //   const totalamounts = parseFloat(totalamount) + (parseFloat(user1) * parseFloat(count));

  //   let strTotal = String(totalamounts);

  //   var res = strTotal.split("/");

  //   setgst(Gst);
  //   setgetway(Getway);
  //   setgetway1(Getway1);
  //   settotal(totalamounts);
  //   console.log(totalamounts)


  //   user1["price"] = res;
  //   user1["totalprice"] = res;
  // };

  const handleChangedate = (e) => {
    const newForm = { ...form, [e.target.name]: e.target.value };
    setform(newForm);

    const fromDate = new Date(form.fromDate).getTime();
    const toDate = new Date(e.target.value).getTime();
    const totalDays = (toDate - fromDate) / (1000 * 60 * 60 * 24);

    const user1 = totalDays * form.price;
    setpot(user1);
    setcount(totalDays);

    const Gst = user1 * 0.01;
    const Getway = user1 * 0.02;
    const Getway1 = parseFloat(totals) * 0.02;

    const totalamount = Gst + Getway;
    const totalamounts = parseFloat(totalamount) + user1;
    const totalamounts123 = user1;
    setgst(Gst);
    setgetway(Getway);
    setgetway1(Getway1);
    settotal(totalamounts);
    setsendprice(totalamounts123);
  };

  const [totals, setotals] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    addbookings();
  };

  const handlechangesss = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);

    const user1 = form.price;

    const Gst = user1 * 0.01;

    const Getway = user1 * 0.02;

    const Getway1 = parseInt(totals) * 0.02;

    const totalamount = Gst + Getway;

    let strTotal = parseInt(user1);

    const totalamounts = parseFloat(totalamount) + parseFloat(form.price);
    const totalamounts123 = form.price;

    setgst(Gst);
    setgetway(Getway);
    setgetway1(Getway1);
    console.log(Getway1)
    settotal(totalamounts);
    setsendprice(totalamounts123);
  };

  const handlechangess = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);

    if (form.timeSlot === "1") {
      const user1 = (pot / 100) * 10;
      setotals(user1);
      const Getway1 = parseInt(user1) * 0.02;
      setgetway1(Getway1);

    } else {
      const user1 = (form.price / 100) * 10;
      setotals(user1);
      const Getway1 = parseInt(user1) * 0.02;
      setgetway1(Getway1);
    }
  };

  // int priceDay = majorPr;
  // double payGatePer = priceDay * 0.02;
  // double payGST = priceDay * 0.01;

  // double co = 0;

  // if (couPercentage != "") {
  //   int couponS = int.parse(couPercentage);
  //   double pe = couponS / 100;
  //   double percen = priceDay * pe;
  //   setState(() {
  //     co = percen;
  //   });
  // } else if (couAmount != "") {
  //   int couponS = int.parse(couAmount);
  //   setState(() {
  //     co = couponS.toDouble();
  //   });
  // }
  const [coup, setcoup] = useState([]);
  const [finalamount, setfinalamount] = useState("");
  console.log(finalamount)

  const [finalamount1, setfinalamount1] = useState("");
  console.log(finalamount1)

  const [user, setuser] = useState([]);

  const handleChangeuser = (e) => {
    let myUser = { ...user };
    myUser[e.target.name] = e.target.value;
    setuser(myUser);
  };

  const getUser = () => {
    var token = sessionStorage.getItem("token");
    var _id = sessionStorage.getItem("UserId");
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/customer/customerappgetdetails",
        { _id },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.customerResult);
      });
  };

  useEffect(() => {
    getUser()
  }, [])

  const addbookings = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    dataArray.append("carId", location.state._id);
    dataArray.append("customerName", user.customerName);
    dataArray.append("phone", user.phone);
    dataArray.append("email", user.email);
    dataArray.append("carPriceId", form.carPriceId);
    if (insukey.exampleCheck1 == "checked") {
      if (form.timeSlot == "6" || form.timeSlot == "12") {
        dataArray.append("insurance", cars1s.insurance);
      } else {
        dataArray.append("insurance", parseFloat(cars1s.insurance) * count);
      }
    } else {
      dataArray.append("insurance", "0");
    }

    if (form.paymentStatus === "0" && form.timeSlot === "1") {
      dataArray.append("price", pot);
    } else if (form.paymentStatus === "0") {
      dataArray.append("price", sendprice);
    } else {
      dataArray.append("price", sendprice);
    }


    if (form.paymentStatus === "0") {
      if (form.timeSlot == "6" || form.timeSlot == "12") {
        if (insukey.exampleCheck1 == "checked") {
          dataArray.append("totalprice", totals + parseFloat(getway1));
        } else {
          dataArray.append("totalprice", totals + parseFloat(getway1));
        }
      } else {
        if (insukey.exampleCheck1 == "checked") {
          dataArray.append("totalprice", totals + parseFloat(getway1));
        } else {
          dataArray.append("totalprice", totals + parseFloat(getway1));
        }
      }
      // dataArray.append("totalprice", totals + parseFloat(cars1s.insurance * count));
    } else {
      if (form.timeSlot == "6" || form.timeSlot == "12") {
        if (insukey.exampleCheck1 == "checked") {
          dataArray.append("totalprice", total + parseFloat(cars1s.insurance));
        } else {
          dataArray.append("totalprice", total);
        }
      } else {
        if (insukey.exampleCheck1 == "checked") {
          dataArray.append("totalprice", total + parseFloat(cars1s.insurance) * count);
        } else {
          dataArray.append("totalprice", total);
        }
      }
      // dataArray.append("totalprice", total);
    }

    dataArray.append("couponCode", form.coupon_code);

    if (form.timeSlot === "1") {
      dataArray.append("timeSlot", "one");
      dataArray.append("fromDate", form.fromDate);
      dataArray.append("toDate", form.toDate);
    } else {
      dataArray.append("timeSlot", form.timeSlot);
      dataArray.append("fromDate", form.fromDate);
      dataArray.append("toDate", form.fromDate);
    }

    dataArray.append("fromTime", form.fromTime);
    dataArray.append("toTime", form.toTime);
    dataArray.append("paymentStatus", form.paymentStatus);
    if (form.paymentStatus === "0") {
      dataArray.append("gst", "0");
      // dataArray.append("transactionCharges", "0");
      dataArray.append("transactionCharges", getway1);
    } else {
      dataArray.append("gst", gst);
      dataArray.append("transactionCharges", getway);
    }

    // if (form.paymentStatus === "0") {
    //   const totalss = parseInt(totals) * 100;
    //   setzzz(totalss);
    // } else {
    //   const totals1 = parseInt(total) * 100;
    //   setzzz(totals1);
    // }
    let finalamount;
    let finalamount1;

    if (form.timeSlot === "6" || form.timeSlot === "12") {
      finalamount = insukey.exampleCheck1 === "checked" ? ((parseFloat(totals) + parseFloat(getway1))).toFixed() : (parseFloat(totals) + parseFloat(getway1)).toFixed();

    } else {
      finalamount = insukey.exampleCheck1 === "checked" ? ((parseFloat(totals) + parseFloat(getway1))).toFixed() : (parseFloat(totals) + parseFloat(getway1)).toFixed();

    }

    if (form.timeSlot === "6" || form.timeSlot === "12") {
      finalamount1 = insukey.exampleCheck1 === "checked" ? ((parseFloat(total) + parseFloat(cars1s.insurance))).toFixed() : parseFloat(total).toFixed();
      console.log(finalamount1)
    } else {
      finalamount1 = insukey.exampleCheck1 === "checked" ? ((parseFloat(total) + parseFloat(cars1s.insurance * count))).toFixed() : parseFloat(total).toFixed();
      console.log(finalamount1)
    }

    // if(form.timeSlot == "6" || form.timeSlot == "12"){
    //   if(insukey.exampleCheck1 == "checked"){
    //     const totalss = parseInt(totals + parseFloat(cars1s.insurance));
    //     setfinalamount(totalss)
    //     // dataArray.append("totalprice", totals + parseFloat(cars1s.insurance));
    //   }else{
    //     const totalss = parseInt(totals);
    //     setfinalamount(totalss)
    //   }
    // }else{
    //   if(insukey.exampleCheck1 == "checked"){
    //     const totals1 = parseInt(total + parseFloat(cars1s.insurance * count));
    //     setfinalamount1(totals1)
    //     // dataArray.append("totalprice", total + parseFloat(cars1s.insurance * count));
    //   }else{
    //     const totals1 = parseInt(total);
    //     setfinalamount1(totals1)
    //     // dataArray.append("totalprice", totals);
    //   }
    // }


    // const totalss = parseInt(totals) * 100;
    // const totals1 = parseInt(total) * 100;

    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/booking/addappcarbooking",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      // key: "rzp_live_QtIdhYMkW8VDdw",
      // rzp_test_HJG5Rtuy8Xh2NB

      .then(
        (res) => {
          if (res.status === 200) {
            if (form.paymentStatus === "0") {
              const options = {
                key: "rzp_live_QtIdhYMkW8VDdw",
                // key: "rzp_test_HJG5Rtuy8Xh2NB",
                amount: finalamount * 100,
                currency: "INR",
                name: "Freedom cars",
                description: "Freedom cars Transaction",
                image:
                  "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",

                handler: function (response) {
                  var token = sessionStorage.getItem("token");
                  const dataArray = new FormData();
                  dataArray.append(
                    "transactionId",
                    response.razorpay_payment_id
                  );
                  dataArray.append("bookingId", res.data.data._id);
                  dataArray.append("paytype", form.paymentStatus);
                  if (
                    response.razorpay_payment_id ===
                    response.razorpay_payment_id
                  ) {
                    dataArray.append("status", "success");
                  } else {
                    dataArray.append("status", "failure");
                  }

                  console.log("dataArray", dataArray);
                  axios
                    .post(
                      "https://api.freedomcars.co.in/v1/app/booking/UpdatePayment",
                      dataArray,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    )
                    .then(
                      (res) => {
                        if (res.status === 200) {
                          toast(res.data.message);
                          navigate("/Mybookings");
                          rzp1.close();
                        }
                      },
                      (error) => {
                        if (error.response && error.response.status === 400) {
                          toast(error.response.data.message);
                          rzp1.close();
                        }
                      }
                    );
                },
                prefill: {
                  name: user.customerName,
                  email: user.email,
                  contact: user.phone,
                },
                notes: {
                  address: "",
                },
                theme: {
                  color: "#015A65",
                },
              };
              const rzp1 = new Razorpay(options);
              rzp1.open();
            } else {
              const options = {
                key: "rzp_live_QtIdhYMkW8VDdw",
                // key: "rzp_test_HJG5Rtuy8Xh2NB",
                amount: finalamount1 * 100,
                currency: "INR",
                name: "Freedom cars",
                description: "Freedom cars Transaction",
                image:
                  "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",

                handler: function (response) {
                  var token = sessionStorage.getItem("token");
                  const dataArray = new FormData();
                  dataArray.append(
                    "transactionId",
                    response.razorpay_payment_id
                  );
                  dataArray.append("bookingId", res.data.data._id);
                  dataArray.append("paytype", form.paymentStatus);
                  if (
                    response.razorpay_payment_id ===
                    response.razorpay_payment_id
                  ) {
                    dataArray.append("status", "success");
                  } else {
                    dataArray.append("status", "failure");
                  }

                  console.log("dataArray", dataArray);
                  axios
                    .post(
                      "https://api.freedomcars.co.in/v1/app/booking/UpdatePayment",
                      dataArray,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    )
                    .then(
                      (res) => {
                        if (res.status === 200) {
                          toast(res.data.message);
                          navigate("/Mybookings");
                          rzp1.close();
                        }
                      },
                      (error) => {
                        if (error.response && error.response.status === 400) {
                          toast(error.response.data.message);
                          rzp1.close();
                        }
                      }
                    );
                },
                prefill: {
                  name: user.customerName,
                  email: user.email,
                  contact: user.phone,
                },
                notes: {
                  address: "",
                },
                theme: {
                  color: "#015A65",
                },
              };
              const rzp1 = new Razorpay(options);
              rzp1.open();
            }
          }
        },

        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const couponsub = (e) => {
    e.preventDefault();
    console.log(form.coupon_code);
    if (form.coupon_code != "" && form.coupon_code != undefined) {
      getcoupons();
    }
  };

  const [cop, setcop] = useState([]);

  const [cop1, setcop1] = useState([]);

  const [cop2, setcop2] = useState([]);

  const getcoupons = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    dataArray.append("coupon_code", form.coupon_code);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/booking/verifycouponcode",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setcop(res.data.message);
            setcoup(res.data.coupon);

            if (res.data.data.coupon_code_type == "price") {
              setcop2(total);

              var totals = total - res.data.data.amount;

              settotal(totals);

              setcop1(res.data.data.amount);
            } else {
              var total1 = res.data.data.percentDiscount / 100;
              setcop2(total);

              var total2 = total * total1;

              setcop1(total2);

              var total3 = total - total2;

              // console.log(total3)

              setcop1(total3);

              settotal(total3);
            }
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  var tzoffset = new Date().getTimezoneOffset() * 60000;
  var localISOTime = new Date(Date.now() + 30 * 60 * 1000 - tzoffset)
    .toISOString()
    .slice(11, 16);

  console.log(localISOTime);

  //  const zzz = parseInt(localISOTime)

  //  console.log(zzz)

  var oldDateObj = new Date();
  var newDateObj = new Date();
  newDateObj.setTime(oldDateObj.getTime() + 30 * 60 * 1000);

  const feature = newDateObj;

  console.log(feature);

  const [insukey, setinsukey] = useState({})
  console.log(insukey)

  const checkhandlechange = (e) => {
    const myDatas = { ...insukey }
    myDatas[e.target.name] = e.target.checked ? 'checked' : 'unchecked';
    setinsukey(myDatas)
  }

  const getCurrentTime = () => {
    const currentTime = new Date();
    const currentHours = currentTime.getHours().toString().padStart(2, '0');
    const currentMinutes = currentTime.getMinutes().toString().padStart(2, '0');
    return `${currentHours}:${currentMinutes}`;
  };



  return (
    <div>
      <Header />
      <main class="main">
        <div class="container">
          <section class="row row--grid">
            <div class="col-12">
              <div class="main__title main__title--page">
                <h1>Booking Information</h1>
              </div>
            </div>

            <div class="col-12 col-lg-7">
              <div class="details">
                <div class=" splide--details details__slider">
                  <Carousel variant="dark">
                    {cars1.map((data, key) => (
                      <Carousel.Item>
                        <img
                          className="d-block"
                          src={"https://api.freedomcars.co.in/" + data}
                          alt="First slide"
                          height="480px"
                        />
                        <Carousel.Caption></Carousel.Caption>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
              <div class="offer">
                <span class="offer__title">Booking Information & Billing</span>
                <ul class="offer__details">
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z" />
                    </svg>
                    {cars1s.seatCount == null ? (
                      <span>0 People</span>
                    ) : (
                      <span>{cars1s.seatCount} People</span>
                    )}
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20.54,6.29,19,4.75,17.59,3.34a1,1,0,0,0-1.42,1.42l1,1-.83,2.49a3,3,0,0,0,.73,3.07l2.95,3V19a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H14V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V16h1a1,1,0,0,1,1,1v2a3,3,0,0,0,6,0V9.83A5,5,0,0,0,20.54,6.29ZM12,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12h8Zm0-9H4V5A1,1,0,0,1,5,4h6a1,1,0,0,1,1,1Zm8,1.42L18.46,9.88a1,1,0,0,1-.24-1l.51-1.54.39.4A3,3,0,0,1,20,9.83Z"></path>
                    </svg>
                    <span>Petrol/Diesel</span>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.088,4.95453c-.00732-.00781-.00952-.01819-.01715-.02582s-.01819-.00995-.02606-.01733a9.97886,9.97886,0,0,0-14.08948,0c-.00787.00738-.01837.00964-.02606.01733s-.00983.018-.01715.02582a10,10,0,1,0,14.1759,0ZM12,20a7.9847,7.9847,0,0,1-6.235-3H9.78027a2.9636,2.9636,0,0,0,4.43946,0h4.01532A7.9847,7.9847,0,0,1,12,20Zm-1-5a1,1,0,1,1,1,1A1.001,1.001,0,0,1,11,15Zm8.41022.00208L19.3999,15H15a2.99507,2.99507,0,0,0-2-2.81573V9a1,1,0,0,0-2,0v3.18427A2.99507,2.99507,0,0,0,9,15H4.6001l-.01032.00208A7.93083,7.93083,0,0,1,4.06946,13H5a1,1,0,0,0,0-2H4.06946A7.95128,7.95128,0,0,1,5.68854,7.10211l.65472.65473A.99989.99989,0,1,0,7.75732,6.34277l-.65466-.65466A7.95231,7.95231,0,0,1,11,4.06946V5a1,1,0,0,0,2,0V4.06946a7.95231,7.95231,0,0,1,3.89734,1.61865l-.65466.65466a.99989.99989,0,1,0,1.41406,1.41407l.65472-.65473A7.95128,7.95128,0,0,1,19.93054,11H19a1,1,0,0,0,0,2h.93054A7.93083,7.93083,0,0,1,19.41022,15.00208Z"></path>
                    </svg>
                    <span>{cars1s.carFeatures[0]}</span>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12,12a1,1,0,1,0,1,1A1,1,0,0,0,12,12Zm9.71-2.36a0,0,0,0,1,0,0,10,10,0,0,0-19.4,0,0,0,0,0,1,0,0,9.75,9.75,0,0,0,0,4.72,0,0,0,0,1,0,0A10,10,0,0,0,9.61,21.7h0a9.67,9.67,0,0,0,4.7,0h0a10,10,0,0,0,7.31-7.31,0,0,0,0,1,0,0,9.75,9.75,0,0,0,0-4.72ZM12,4a8,8,0,0,1,7.41,5H4.59A8,8,0,0,1,12,4ZM4,12a8.26,8.26,0,0,1,.07-1H6v2H4.07A8.26,8.26,0,0,1,4,12Zm5,7.41A8,8,0,0,1,4.59,15H7a2,2,0,0,1,2,2Zm4,.52A8.26,8.26,0,0,1,12,20a8.26,8.26,0,0,1-1-.07V18h2ZM13.14,16H10.86A4,4,0,0,0,8,13.14V11h8v2.14A4,4,0,0,0,13.14,16ZM15,19.41V17a2,2,0,0,1,2-2h2.41A8,8,0,0,1,15,19.41ZM19.93,13H18V11h1.93A8.26,8.26,0,0,1,20,12,8.26,8.26,0,0,1,19.93,13Z"></path>
                    </svg>
                    <span>{cars1s.vehicleType}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-12 col-lg-5">
              <div class="offer">
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div class="sign__group">
                    <label
                      for="fullname"
                      class="sign__label sign__label--modal"
                    >
                      Name
                    </label>
                    <input
                      id="fullname"
                      type="text"
                      className="form-control"
                      name="customerName"
                      value={user.customerName}
                      placeholder="Enter your Name"
                      onChange={(e) => {
                        handleChangeuser(e);
                      }}
                    // defaultValue={sessionStorage.getItem("name")}
                    />
                  </div>
                  <div class="sign__group">
                    <label for="phone" class="sign__label sign__label--modal">
                      Phone
                    </label>
                    <input
                      id="phone"
                      type="text"
                      className="form-control"
                      placeholder="Enter your Phone"
                      name="phone"
                      value={user.phone}
                      onChange={(e) => {
                        handleChangeuser(e);
                      }}
                    // defaultValue={sessionStorage.getItem("phone")}
                    />
                  </div>

                  <div class="sign__group">
                    <label
                      for="delivery"
                      class="sign__label sign__label--modal"
                    >
                      Email
                    </label>
                    <input
                      id="delivery"
                      type="text"
                      className="form-control"
                      placeholder="Enter your Email"
                      name="email"
                      value={user.email}
                      onChange={(e) => {
                        handleChangeuser(e);
                      }}
                    // defaultValue={sessionStorage.getItem("email")}
                    />
                  </div>

                  <div class="sign__group">
                    <label
                      for="delivery"
                      class="sign__label sign__label--modal"
                    >
                      Select Package
                    </label>
                    <select
                      value={form.timeSlot}
                      name="timeSlot"
                      onChange={(e) => {
                        handleChange12(e);
                      }}
                      className="form-select"
                      required
                    >
                      <option value="">Select Package</option>
                      <option value="6">06 Hours</option>
                      <option value="12">12 Hours</option>
                      <option value="1">Day</option>
                    </select>
                  </div>

                  <div class="sign__group">
                    <label
                      for="delivery"
                      class="sign__label sign__label--modal"
                    >
                      km / Price
                    </label>
                    <select
                      className="form-select"
                      required
                      name="priceId"
                      value={form.priceId}
                      onChange={(e) => handlechangeprice(e)}
                    >
                      <option value="">Select kms</option>
                      {Kms.map((data, key) => (
                        <option key={key} value={data._id + "/" + data.price}>
                          {form.timeSlot == "1" ? (
                            <>
                              {data.unlimitedStatus == "Yes" ? (
                                <>Unlimited/kms/₹{data.price}</>
                              ) : (
                                <>
                                  {data.kms}kms/₹{data.price}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {data.kms}kms/₹{data.price}
                            </>
                          )}
                        </option>
                      ))}
                      ;
                    </select>
                  </div>

                  {form.timeSlot == "1" ? (
                    <Row>
                      <Col md="6">
                        <div class="sign__group">
                          <label
                            for="delivery"
                            class="sign__label sign__label--modal"
                          >
                            Pickup Date
                          </label>
                          <input
                            id="delivery"
                            required
                            type="date"
                            className="form-control"
                            placeholder="Enter your Email"
                            name="fromDate"
                            min={new Date().toISOString().split("T")[0]}
                            value={form.fromDate}
                            onChange={(e) => {
                              handleChangedates(e);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div class="sign__group">
                          <label
                            for="delivery"
                            class="sign__label sign__label--modal"
                          >
                            Delivery Date
                          </label>
                          <input
                            name="toDate"
                            required
                            min={dds}
                            value={form.toDate}
                            onChange={(e) => {
                              handleChangedate(e);
                            }}
                            type="date"
                            className="form-control"
                            id="basicpill-Declaration-input10"
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md="12">
                        <div class="sign__group">
                          <label
                            for="delivery"
                            class="sign__label sign__label--modal"
                          >
                            Date
                          </label>
                          <input
                            id="delivery"
                            required
                            type="date"
                            className="form-control"
                            placeholder="Enter your Email"
                            name="fromDate"
                            min={new Date().toISOString().split("T")[0]}
                            value={form.fromDate}
                            onChange={(e) => {
                              handlechangesss(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  {form.timeSlot == "6" || form.timeSlot == "12" ? (
                    <Row>
                      {form.fromDate == new Date().toISOString().split("T")[0] ? (

                        <Col md="6">
                          <div class="sign__group">
                            <label
                              for="delivery"
                              class="sign__label sign__label--modal"
                            >
                              Pickup Time
                            </label>
                            <input
                              id="delivery"
                              type="time"
                              // type="datetime-local"
                              required
                              className="form-control without_ampm"
                              placeholder="Enter your time"
                              name="fromTime"
                              min={getCurrentTime()}
                              // min={localISOTime}
                              // min={new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                              value={form.fromTime}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        <Col md="6">
                          <div class="sign__group">
                            <label
                              for="delivery"
                              class="sign__label sign__label--modal"
                            >
                              Pickup Time
                            </label>
                            <input
                              id="delivery"
                              type="time"
                              // type="datetime-local"
                              required
                              className="form-control without_ampm"
                              placeholder="Enter your time"
                              name="fromTime"
                              // min={getCurrentTime()}
                              // min={localISOTime}
                              // min={new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                              value={form.fromTime}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="6">
                        <div class="sign__group">
                          <label
                            for="delivery"
                            class="sign__label sign__label--modal"
                          >
                            Delivery Time
                          </label>
                          <input
                            id="delivery"
                            type="time"
                            // type="datetime-local"
                            required
                            className="form-control without_ampm"
                            placeholder="Enter your time"
                            name="toTime"
                            min={form.fromTime}
                            value={form.toTime}
                            onChange={(e) => {
                              handlechange(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md="6">
                        <div class="sign__group">
                          <label
                            for="delivery"
                            class="sign__label sign__label--modal"
                          >
                            Pickup Time
                          </label>
                          <input
                            id="delivery"
                            type="time"
                            // type="datetime-local"
                            required
                            className="form-control without_ampm"
                            placeholder="Enter your time"
                            name="fromTime"
                            // min={getCurrentTime()}
                            // min={localISOTime}
                            // min={new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            value={form.fromTime}
                            onChange={(e) => {
                              handlechange(e);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div class="sign__group">
                          <label
                            for="delivery"
                            class="sign__label sign__label--modal"
                          >
                            Delivery Time
                          </label>
                          <input
                            id="delivery"
                            type="time"
                            // type="datetime-local"
                            required
                            className="form-control without_ampm"
                            placeholder="Enter your time"
                            name="toTime"
                            // min={form.fromTime}
                            value={form.toTime}
                            onChange={(e) => {
                              handlechange(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}




                  {/* <div class="sign__group">
                    <label
                      for="delivery"
                      class="sign__label sign__label--modal"
                    >
                      Apply Coupon
                    </label>
                    <select
                      value={form.couponCode}
                      name="couponCode"
                      onChange={(e) => {
                        handlechangess(e);
                      }}
                      className="form-select"
                      required
                    >
                      <option value="">Select type</option>
                      {cop.map((data, key) => {
                        return (
                          <option key={key} value={data._id}>
                            {data.coupon_code}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                  <div class="sign__group mt-2">
                    <label
                      for="delivery"
                      class="sign__label sign__label--modal"
                    >
                      Make Payment
                    </label>

                    <select
                      value={form.paymentStatus}
                      name="paymentStatus"
                      onChange={(e) => {
                        handlechangess(e);
                      }}
                      className="form-select"
                      required
                      disabled={!form.fromDate}
                    >
                      <option value="">Select type</option>
                      <option value="1">Full Payment</option>
                      <option value="0">Partial Payment</option>
                    </select>
                  </div>
                  {form.paymentStatus == "1" ? (
                    <Row>
                      <Col md="6">
                        <Row>
                          <Col><Label> Insurance:</Label></Col>
                          <Col>
                            <div class="form-group form-check">
                              <input
                                // value="checked"
                                name="exampleCheck1"
                                onChange={checkhandlechange}
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                              />
                              {/* <input value="chaecked" name="exampleCheck1" onChange={checkhandlechange} type="checkbox" class="form-check-input" id="exampleCheck1" /> */}
                              <label class="form-check-label" for="exampleCheck1">Yes</label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        {insukey.exampleCheck1 == "checked" ? (
                          <Label>₹ : {cars1s.insurance}</Label>
                        ) : ""}
                      </Col>
                    </Row>
                  ) : ""}

                  {/* {form.paymentStatus === "1" ? (
                    <>
                      <Label for="basicpill-declaration-input10">
                        Coupon Code
                      </Label>
                      <InputGroup>
                        <input
                          value={form.coupon_code}
                          name="coupon_code"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type="text"
                          className="form-control"
                          id="basicpill-namecard-input11"
                          placeholder="Code"
                        />

                        {cop == null || cop == "" ? (
                          <button
                            onClick={(e) => {
                              couponsub(e);
                            }}
                            style={{
                              cursor: "pointer",
                              background: "#397F87",
                              color: "white",
                            }}
                            className="input-group-text"
                          >
                            {" "}
                            Apply
                          </button>
                        ) : (
                          ""
                        )}
                      </InputGroup>
                      {cop == null || cop == "" ? (
                        <small className="text-danger">Invalid Coupon</small>
                      ) : (
                        <small className="text-success">
                          Coupon Applied Successfully
                        </small>
                      )}
                    </>
                  ) : (
                    ""
                  )} */}

                  <hr></hr>
                  <Row>
                    <Col>
                      <span>From Date : </span>
                      <span>{form.fromDate}</span>
                    </Col>
                    <Col>
                      <span>To Date : </span>
                      {form.timeSlot == "1" ? (
                        <>
                          <span>{form.toDate}</span>
                        </>
                      ) : (
                        <span>{form.fromDate}</span>
                      )}
                    </Col>
                  </Row>

                  <hr></hr>

                  {form.paymentStatus == "0" ? (
                    <>
                      {/* {insukey.exampleCheck1 == "checked" ? (
                        <Row>
                          <Col>
                            <span>Insurance:</span>
                          </Col>
                          <Col>
                            <h6> +{cars1s.insurance}</h6>
                          </Col>
                        </Row>
                      ) : ""} */}

                      <Row>
                        <Col>
                          <span>Total Price:</span>
                        </Col>{" "}

                        <Col>
                          {cop == "" ? (
                            <h6>{totals}</h6>

                          ) : (
                            <>
                              {" "}
                              <h6>{totals}</h6>
                            </>
                          )}
                        </Col>
                        {/* ) : (
                          <Col>
                            {cop == "" ? (
                              <h6>{pot}</h6>
                            ) : (
                              <>
                                {" "}
                                <h6>{form.price}</h6>
                              </>
                            )}
                          </Col>

                        )} */}


                      </Row>
                      <Row>
                        {" "}
                        <Col>
                          <span>Payment Getway Tax:</span>
                        </Col>{" "}
                        <Col>
                          <h6>+{(getway1).toFixed(2)}</h6>
                        </Col>
                      </Row>

                      {/* {insukey.exampleCheck1 == "checked" ? (
                          <Row>
                            <Col>
                              <span>Insurance:</span>
                            </Col>
                            <Col>
                              {form.timeSlot == "6" || form.timeSlot == "12" ? (
                                <h6> + {cars1s.insurance}</h6>
                              ) : (<h6> + {cars1s.insurance} x {count} = {cars1s.insurance * count}</h6>)}
                            </Col>
                          </Row>
                        ) : ""} */}
                      <hr />

                      {/* <Row>
                        <Col>
                          <span>Total Price:</span>
                        </Col>{" "}
                        <Col>
                          {form.timeSlot == "1" ? (
                            <h6>{pot}</h6>
                          ) : (
                            <h6>{form.price}</h6>
                          )}
                        </Col>
                      </Row> */}
                      <Row>
                        <Col>
                          <span>Total Payable:</span>{" "}
                        </Col>{" "}
                        <Col>
                          <Col>
                            {form.timeSlot == "6" || form.timeSlot == "12" ? (
                              <>
                                {insukey.exampleCheck1 == "checked" ? (
                                  <h5>{(totals + parseFloat(getway1)).toFixed()}</h5>) : (<h5>{(totals + parseFloat(getway1)).toFixed()}</h5>)}
                              </>
                            ) : (
                              <>
                                {insukey.exampleCheck1 == "checked" ? (
                                  <h5>{(totals + parseFloat(getway1)).toFixed()}</h5>) : (<h5>{(totals + parseFloat(getway1)).toFixed()}</h5>)}
                              </>
                            )}
                          </Col>

                          {/* {insukey.exampleCheck1 == "checked" ? (
                            <h5>{totals + parseFloat(cars1s.insurance)}</h5>) : (<h5>{totals}</h5>)} */}
                        </Col>

                        {/* <h5>{totals}</h5> */}
                        {/* </Col> */}
                      </Row>
                      <hr></hr>
                      *As per our policy you need to clear 10% of your total
                      payable while booking ride for security pourposes
                    </>
                  ) : (
                    ""
                  )}
                  {form.paymentStatus == "1" ? (
                    <>
                      <Row>
                        <Col>
                          <span>Days Count:</span>
                        </Col>{" "}
                        <Col>
                          <h6>
                            {form.timeSlot == "6" ? <>6 Hours</> : ""}
                            {form.timeSlot == "12" ? <>12 Hours</> : ""}
                            {form.timeSlot == "1" ? (
                              <>
                                {count == "1" ? (
                                  <>{count} day</>
                                ) : (
                                  <> {count} days</>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span>Price:</span>
                        </Col>{" "}
                        <Col>
                          {form.timeSlot == "6" || form.timeSlot == "12" ? (
                            <h6>{form.price}</h6>) :
                            (<h6>{form.price} x {count} = {form.price * count}</h6>)}
                        </Col>
                      </Row>



                      <Row>
                        {" "}
                        <Col>
                          <span>Payment Getway Tax:</span>
                        </Col>{" "}
                        <Col>
                          <h6>+{getway.toFixed(2)}</h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span>Gst:</span>
                        </Col>
                        <Col>
                          <h6>+{gst.toFixed(2)}</h6>
                        </Col>
                      </Row>
                      {insukey.exampleCheck1 == "checked" ? (
                        <Row>
                          <Col>
                            <span>Insurance:</span>
                          </Col>
                          <Col>
                            {form.timeSlot == "6" || form.timeSlot == "12" ? (
                              <h6> + {cars1s.insurance}</h6>
                            ) : (<h6> + {cars1s.insurance} x {count} = {cars1s.insurance * count}</h6>)}
                          </Col>
                        </Row>
                      ) : ""}

                      <Row>
                        <Col>
                          <span>Total Price:</span>
                        </Col>{" "}
                        {insukey.exampleCheck1 == "checked" ? (
                          <Col>
                            {cop == "" ? (
                              <>
                                {form.timeSlot == "6" || form.timeSlot == "12" ? (
                                  <h6>{parseFloat(total) + parseFloat(cars1s.insurance)}</h6>) : (
                                  <h6>{parseFloat(total) + parseFloat(cars1s.insurance * count)}</h6>
                                )}
                              </>

                            ) : (
                              <>
                                {form.timeSlot == "6" || form.timeSlot == "12" ? (
                                  <h6>{parseFloat(cop2) + parseFloat(cars1s.insurance)}</h6>) : (
                                  <h6>{parseFloat(cop2) + parseFloat(cars1s.insurance * count)}</h6>
                                )}
                              </>
                            )}
                          </Col>
                        ) : (
                          <Col>
                            {cop == "" ? (
                              <h6>{total}</h6>
                            ) : (
                              <>
                                {" "}
                                <h6>{cop2}</h6>
                              </>
                            )}
                          </Col>

                        )}


                      </Row>
                      {/* <Row>
                        <Col>
                          <span>Discount price:</span>
                        </Col>
                        <Col>
                          <h6>-{cop1}</h6>
                        </Col>
                      </Row> */}
                      <hr></hr>

                      <Row>
                        <Col>
                          <span>Total Payable:</span>{" "}
                        </Col>{" "}
                        <Col>
                          {form.timeSlot === "6" || form.timeSlot === "12" ? (
                            <>
                              {insukey.exampleCheck1 === "checked" ? (
                                <h5>{(parseFloat(total) + parseFloat(cars1s.insurance)).toFixed()}</h5>
                              ) : (
                                <h5>{parseFloat(total).toFixed()}</h5>
                              )}
                            </>
                          ) : (
                            <>
                              {insukey.exampleCheck1 === "checked" ? (
                                <h5>{(parseFloat(total) + parseFloat(cars1s.insurance * count)).toFixed()}</h5>
                              ) : (
                                <h5>{parseFloat(total).toFixed()}</h5>
                              )}
                            </>
                          )}
                          {/* {form.timeSlot == "6" || form.timeSlot == "12" ? (
                              <>
                                {insukey.exampleCheck1 == "checked" ? (
                                  <h5>{(parseFloat(total) + parseFloat(cars1s.insurance)).toFixed()}</h5>) : (<h5>{parseFloat(total).toFixed()}</h5>)}
                              </>) : (
                              <>
                                {insukey.exampleCheck1 == "checked" ? (
                                  <h5>{(parseFloat(total) + (parseFloat(cars1s.insurance)* parseFloat(count))).toFixed()}</h5>) : (<h5>{parseFloat(total).toFixed()}</h5>)}
                              </>
                            )} */}

                        </Col>
                      </Row>
                      <hr></hr>
                    </>
                  ) : (
                    ""
                  )}

                  <button type="submit" class="sign__btn sign__btn--modal">
                    <span>Proceed</span>
                  </button>
                </form>
              </div>
            </div>

            <ToastContainer />
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CarBooking;
