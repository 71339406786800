import Home from "./Componentas/Home";
import "./Componentas/Common.css";
import Otp from "./Componentas/Otp";
import Car from "./Componentas/Car";
import ScrollTop from "./ScrollTop";
import Login from "./Componentas/Login";
import Wallet from "./Componentas/Wallet";
import Signup from "./Componentas/Signup";
import Contact from "./Componentas/Contact";
import Favourites from "./Componentas/Favourites";
import Profile from "./Componentas/Profile";
import Cardetails from "./Componentas/Cardetails";
import CarBooking from "./Componentas/CarBooking";
import Mybookings from "./Componentas/Mybookings";
import DrivePoints from "./Componentas/DrivePoints";
import Forgetpassword from "./Componentas/Forgetpassword";
import ChangePassword from "./Componentas/ChangePassword";
import MybookingHistory from "./Componentas/MybookingHistory";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacypolicy from "./Componentas/Privacypolicy";
import Termsconditions from "./Componentas/Termsconditions";
import Refundpolicy from "./Componentas/Refundpolicy";
import Copun from "./Componentas/Copun";
import Doc from "./Componentas/Doc";
import Favdetails from "./Componentas/Favdetails";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollTop></ScrollTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/Contact" element={<Contact />} />

          <Route path="/Favdetails" element={<Favdetails />} />

          <Route path="/Car" element={<Car />} />
          <Route path="/Wallet" element={<Wallet />} />
          
          <Route path="/Cardetails" element={<Cardetails />} />
          <Route path="/CarBooking" element={<CarBooking />} />

          <Route path="/Mybookings" element={<Mybookings />} />
          <Route path="/DrivePoints" element={<DrivePoints />} />
          <Route path="/MybookingHistory" element={<MybookingHistory />} />



          <Route path="/favourites" element={<Favourites />} />
          <Route path="/Coupon" element={<Copun />} />
          <Route path="/Doc" element={<Doc />} />



          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Termsconditions" element={<Termsconditions />} />
          <Route path="/Refundpolicy" element={<Refundpolicy />} />

          <Route path="/Otp" element={<Otp />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Forgetpassword" element={<Forgetpassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
