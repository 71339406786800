import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Img from "../img/cars/1-1.jpg";
import { NavLink } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/modal";

import { Input, Label, Row } from "reactstrap";

import { useNavigate } from "react-router-dom";

function Cardetails() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [form, setform] = useState([]);

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };
  const [Files, setFiles] = useState("");
  const changeHandler = (e) => {
    setFiles(e.target.files);
  };

  const location = useLocation();
  console.log(location);

  useEffect(() => {
    getcars();
    getfavid();
  }, []);

  const [car, setcar] = useState([]);
  console.log(car);

  const [car1, setcar1] = useState([]);
  console.log(car1);

  const [Price, setPrice] = useState([]);
  const [favid, setfavid] = useState([]);
  console.log(favid.isFavorite);

  const [fes, setfes] = useState([]);

  const getcars = () => {
    const dataArray = new FormData();
    dataArray.append("_id", location.state.carId);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/carmodel/getwebcarmodel",
        dataArray
      )
      .then((res) => {
        setcar(res.data.carResult);
        setcar1(res.data.carResult.carImage);
        setPrice(res.data.carResult.carPrices[0]);
        setfes(res.data.carResult.carFeatures[0]);
      });
  };

  const getfavid = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    dataArray.append("carId", location.state._id);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/favourites/getfavouritesstatusbycarid",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setfavid(res.data.isFavorite);
      });
  };

  const addfavirate = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    dataArray.append("carId", location.state._id);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/favourites/addfaviourites",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            getfavid();
            getcars();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            getfavid();
            getcars();
          }
        }
      );
  };

  const navigate = useNavigate();

  const toComponentd = (car) => {
    const tr = sessionStorage.getItem("status");
    if (tr === "true") {
      navigate("/carbooking", { state: car });
    } else {
      navigate("/Doc", { state: car });
    }
  };
  const toComponente = (car) => {
    navigate("/login", { state: car });
  };

  var roles = sessionStorage.getItem("role");

  const handlesession = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleChanges = (e) => {
    let myUser = { ...form };
    myUser.countryId = e.target.value;
    setform(myUser);
  };

  const [Files1, setFiles1] = useState("");
  const [Files2, setFiles2] = useState("");
  const [Files3, setFiles3] = useState("");
  const [Files4, setFiles4] = useState("");
  const [Files5, setFiles5] = useState("");
  const [Files6, setFiles6] = useState("");
  const [Files7, setFiles7] = useState("");
  const [Files8, setFiles8] = useState("");
  const [Files9, setFiles9] = useState("");
  const [Files10, setFiles10] = useState("");
  const [Files11, setFiles11] = useState("");
  const [Files12, setFiles12] = useState("");
  const [Files13, setFiles13] = useState("");
  const [Files14, setFiles14] = useState("");
  // const [Files3, setFiles3] = useState("");

  const changeHandler1 = (e) => {
    setFiles1(e.target.files);
  };
  const changeHandler2 = (e) => {
    setFiles2(e.target.files);
  };
  const changeHandler3 = (e) => {
    setFiles3(e.target.files);
  };
  const changeHandler4 = (e) => {
    setFiles4(e.target.files);
  };
  const changeHandler5 = (e) => {
    setFiles5(e.target.files);
  };
  const changeHandler6 = (e) => {
    setFiles6(e.target.files);
  };
  const changeHandler7 = (e) => {
    setFiles7(e.target.files);
  };
  const changeHandler8 = (e) => {
    setFiles8(e.target.files);
  };
  const changeHandler9 = (e) => {
    setFiles9(e.target.files);
  };
  const changeHandler10 = (e) => {
    setFiles10(e.target.files);
  };
  const changeHandler11 = (e) => {
    setFiles11(e.target.files);
  };
  const changeHandler12 = (e) => {
    setFiles12(e.target.files);
  };
  const changeHandler13 = (e) => {
    setFiles13(e.target.files);
  };
  const changeHandler14 = (e) => {
    setFiles14(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editcutomer();
  };

  // --form 'documentType="aadhar"' \
  // --form 'aadharNumber="121231415161718191"' \
  // --form 'selfieImg=@"/C:/Users/NISHAT FATEMA/Downloads/logo.jpg"' \
  // --form 'aadharFrontImg=@"/C:/Users/NISHAT FATEMA/Downloads/Flag_of_Uruguay.png"' \
  // --form 'aadharBackImg=@"/C:/Users/NISHAT FATEMA/Downloads/Flag_of_Monaco.png"' \
  // --form 'voterIdNumber=""' \
  // --form 'voterIdFrontImg=@"/path/to/file"' \
  // --form 'voterIdBackImg=@"/path/to/file"' \
  // --form 'passportFrontImg=@"/path/to/file"' \
  // --form 'passportBackImg=@"/path/to/file"' \
  // --form 'interPassportImg=@"/path/to/file"' \
  // --form 'photoidImg=@"/path/to/file"' \
  // --form 'visaImg=@"/path/to/file"' \
  // --form 'dlFrontImg=@"/C:/Users/NISHAT FATEMA/Downloads/Vanuatu_flag.png"' \
  // --form 'dlBackImg=@"/C:/Users/NISHAT FATEMA/Downloads/Flag_of_Tonga.png"' \
  // --form 'interDlFrontImg=@"/path/to/file"' \
  // --form 'interDlBackImg=@"/path/to/file"'

  const editcutomer = () => {
    var token = sessionStorage.getItem("token");

    const dataArray = new FormData();
    dataArray.append("documentType", form.documentType);
    dataArray.append("aadharNumber", form.aadharNumber);
    for (let i = 0; i < Files4.length; i++) {
      dataArray.append("aadharFrontImg", Files4[i]);
    }
    for (let i = 0; i < Files5.length; i++) {
      dataArray.append("aadharBackImg", Files5[i]);
    }
    dataArray.append("voterIdNumber", form.voterIdNumber);

    for (let i = 0; i < Files6.length; i++) {
      dataArray.append("voterIdFrontImg", Files6[i]);
    }
    for (let i = 0; i < Files7.length; i++) {
      dataArray.append("voterIdBackImg", Files7[i]);
    }

    for (let i = 0; i < Files8.length; i++) {
      dataArray.append("passportFrontImg", Files8[i]);
    }
    for (let i = 0; i < Files9.length; i++) {
      dataArray.append("passportBackImg", Files9[i]);
    }

    for (let i = 0; i < Files12.length; i++) {
      dataArray.append("interPassportImg", Files12[i]);
    }
    for (let i = 0; i < Files13.length; i++) {
      dataArray.append("interDlFrontImg", Files13[i]);
    }
    for (let i = 0; i < Files14.length; i++) {
      dataArray.append("interDlBackImg", Files14[i]);
    }

    for (let i = 0; i < Files11.length; i++) {
      dataArray.append("visaImg", Files11[i]);
    }

    for (let i = 0; i < Files2.length; i++) {
      dataArray.append("dlFrontImg", Files2[i]);
    }
    for (let i = 0; i < Files3.length; i++) {
      dataArray.append("dlBackImg", Files3[i]);
    }

    for (let i = 0; i < Files10.length; i++) {
      dataArray.append("photoidImg", Files10[i]);
    }

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("selfieImg", Files[i]);
    }

    axios
      .put(
        "https://api.freedomcars.co.in/v1/app/customer/editapp-customerdocuments",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [isClicked, setIsClicked] = useState(false);

  function handleClick() {
    setIsClicked((prevValue) => {
      return !prevValue;
    });
  }
  console.log(isClicked);

  return (
    <div>
      <Header />

      <main class="main">
        <div class="container">
          <section class="row row--grid">
            <div class="col-12">
              <ul class="breadcrumb">
              <li class="breadcrumb__item">
                <NavLink to="/"> <a >Home</a></NavLink>
                </li>
                <li class="breadcrumb__item">
                <NavLink to="/car"> <a>Explore cars</a></NavLink>
                </li>
                <li class="breadcrumb__item breadcrumb__item--active">
                  {car.carBrandName}
                </li>
              </ul>
            </div>

            <div class="col-12">
              <div class="main__title main__title--page">
                <h1>{car.carBrandName}</h1>
              </div>
            </div>

            <div class="col-12 col-lg-7">
              <div class="details">
                <div class=" splide--details details__slider">
                  <Carousel variant="dark">
                    {car1.map((data, key) => (
                      <Carousel.Item>
                        <img
                          className="d-block"
                          src={"https://api.freedomcars.co.in/" + data}
                          alt="First slide"
                          height="480px"
                        />
                        <Carousel.Caption></Carousel.Caption>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-5">
              <div class="offer">
                <span class="offer__title">Offer</span>
                <div class="offer__wrap">
                  <span class="offer__price">
                    ₹{Price.onedayPrice} <sub>/Day</sub>
                  </span>
                  {/* {favid == true ? (
                    <button
                      style={{ background: "#ff000059" }}
                      class="offer__favorite"
                      type="button"
                      aria-label="Add to favorite"
                      onClick={() => {
                        addfavirate();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
                      </svg>
                    </button>
                  ) : (
                    <button
                      class="offer__favorite"
                      type="button"
                      aria-label="Add to favorite"
                      onClick={() => {
                        addfavirate();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z" />
                      </svg>
                    </button>
                  )} */}

                  {/* <div className={"idea-list" } ><p>{favid.title} {isClicked ? 
<button style={{color:"blue"}} onClick={handleClick}/> :<button style={{color:"red"}} onClick={handleClick}/>}</p>

</div> */}

                  {roles == "Customer" ? (
                    <>
                      {/* <a
                        class="offer__rent open-modal"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          toComponentd(car);
                        }}
                      >
                        <span>Book now</span>
                      </a> */}

                      <Button
                        style={{ cursor: "pointer", background: "#015A65" }}
                        className="btn"
                        onClick={() => {
                          toComponentd(car);
                        }}
                      >
                        Book Now
                      </Button>
                    </>
                  ) : (
                    // <a
                    //   class="offer__rent open-modal"
                    //   style={{ cursor: "pointer" }}
                    //   onClick={() => {
                    //     toComponente(car);
                    //   }}
                    // >
                    //   <span>Book now</span>
                    // </a>

                    <Button
                      style={{ cursor: "pointer", background: "#015A65" }}
                      className="btn"
                      onClick={() => {
                        toComponente(car);
                      }}
                    >
                      Book Now
                    </Button>
                  )}

                  {/* <Button
                    // style={{ cursor: "pointer", background: "#015A65" }}
                    color="primary"
                    className="ml-2"

                    onClick={() => {
                      handleShow();
                    }}
                  >
                    Add Document
                  </Button> */}
                </div>

                <span class="offer__title">Car Details</span>
                <ul class="offer__list">
                  <li>
                    <span class="offer__list-name"> Car Type</span>
                    <span class="offer__list-value">{car.carType}</span>
                  </li>

                  <li>
                    <span class="offer__list-name"> Car Brand Name</span>
                    <span class="offer__list-value">{car.carBrandName}</span>
                  </li>

                  <li>
                    <span class="offer__list-name">Car Model Name</span>
                    <span class="offer__list-value">{car.carModelName}</span>
                  </li>
                  <li>
                    <span class="offer__list-name"> Car Colour</span>
                    <span class="offer__list-value">
                      {car.carColorAvailble}
                    </span>
                  </li>

                  <li>
                    <span class="offer__list-name">Day Payment</span>
                    <span class="offer__list-value offer__list-value--dark">
                      ₹{Price.onedayPrice}
                    </span>
                  </li>

                  <li>
                    <span class="offer__list-name">Disposition fee</span>
                    <span class="offer__list-value"></span>
                  </li>
                </ul>

                <span class="offer__title">Car Features</span>
                <ul class="offer__details">
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"></path>
                    </svg>
                    {car.seatCount == null ? (
                      <span>0 People</span>
                    ) : (
                      <span>{car.seatCount} People</span>
                    )}
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M20.54,6.29,19,4.75,17.59,3.34a1,1,0,0,0-1.42,1.42l1,1-.83,2.49a3,3,0,0,0,.73,3.07l2.95,3V19a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H14V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V16h1a1,1,0,0,1,1,1v2a3,3,0,0,0,6,0V9.83A5,5,0,0,0,20.54,6.29ZM12,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12h8Zm0-9H4V5A1,1,0,0,1,5,4h6a1,1,0,0,1,1,1Zm8,1.42L18.46,9.88a1,1,0,0,1-.24-1l.51-1.54.39.4A3,3,0,0,1,20,9.83Z"></path>
                    </svg>
                    <span>Petrol/Diesel</span>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.088,4.95453c-.00732-.00781-.00952-.01819-.01715-.02582s-.01819-.00995-.02606-.01733a9.97886,9.97886,0,0,0-14.08948,0c-.00787.00738-.01837.00964-.02606.01733s-.00983.018-.01715.02582a10,10,0,1,0,14.1759,0ZM12,20a7.9847,7.9847,0,0,1-6.235-3H9.78027a2.9636,2.9636,0,0,0,4.43946,0h4.01532A7.9847,7.9847,0,0,1,12,20Zm-1-5a1,1,0,1,1,1,1A1.001,1.001,0,0,1,11,15Zm8.41022.00208L19.3999,15H15a2.99507,2.99507,0,0,0-2-2.81573V9a1,1,0,0,0-2,0v3.18427A2.99507,2.99507,0,0,0,9,15H4.6001l-.01032.00208A7.93083,7.93083,0,0,1,4.06946,13H5a1,1,0,0,0,0-2H4.06946A7.95128,7.95128,0,0,1,5.68854,7.10211l.65472.65473A.99989.99989,0,1,0,7.75732,6.34277l-.65466-.65466A7.95231,7.95231,0,0,1,11,4.06946V5a1,1,0,0,0,2,0V4.06946a7.95231,7.95231,0,0,1,3.89734,1.61865l-.65466.65466a.99989.99989,0,1,0,1.41406,1.41407l.65472-.65473A7.95128,7.95128,0,0,1,19.93054,11H19a1,1,0,0,0,0,2h.93054A7.93083,7.93083,0,0,1,19.41022,15.00208Z"></path>
                    </svg>
                    <span>{fes}</span>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12,12a1,1,0,1,0,1,1A1,1,0,0,0,12,12Zm9.71-2.36a0,0,0,0,1,0,0,10,10,0,0,0-19.4,0,0,0,0,0,1,0,0,9.75,9.75,0,0,0,0,4.72,0,0,0,0,1,0,0A10,10,0,0,0,9.61,21.7h0a9.67,9.67,0,0,0,4.7,0h0a10,10,0,0,0,7.31-7.31,0,0,0,0,1,0,0,9.75,9.75,0,0,0,0-4.72ZM12,4a8,8,0,0,1,7.41,5H4.59A8,8,0,0,1,12,4ZM4,12a8.26,8.26,0,0,1,.07-1H6v2H4.07A8.26,8.26,0,0,1,4,12Zm5,7.41A8,8,0,0,1,4.59,15H7a2,2,0,0,1,2,2Zm4,.52A8.26,8.26,0,0,1,12,20a8.26,8.26,0,0,1-1-.07V18h2ZM13.14,16H10.86A4,4,0,0,0,8,13.14V11h8v2.14A4,4,0,0,0,13.14,16ZM15,19.41V17a2,2,0,0,1,2-2h2.41A8,8,0,0,1,15,19.41ZM19.93,13H18V11h1.93A8.26,8.26,0,0,1,20,12,8.26,8.26,0,0,1,19.93,13Z"></path>
                    </svg>
                    <span>{car.vehicleType}</span>
                  </li>
                </ul>
              </div>
            </div>
            <ToastContainer />
          </section>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add doc</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              class="modal__form"
            >
              <Row className="mt-3">
                <div className="mb-3">
                  <Label className="mr-4" for="basicpill-firstname-input1">
                    Image
                  </Label>

                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Image"
                    name="profileImg"
                    value={form.profileImg}
                    onChange={changeHandler}
                  />
                </div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Select Aadhar / vote / Passport
                    <span className="text-danger">*</span>
                  </Label>

                  <select
                    name="documentType"
                    value={form.documentType}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    // onChange={handleSelectChange3}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="aadhar">Aadhar</option>
                    <option value="voterId">Vote Id</option>
                    <option value="passport">Passport</option>
                  </select>
                </div>

                {form.documentType == "voterId" ? (
                  <>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Vote Id <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder=" Vote Id"
                        required
                        name="voterIdNumber"
                        value={form.voterIdNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Vote ID Front
                      </Label>

                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input3"
                        name="voterIdFrontImg"
                        value={form.voterIdFrontImg}
                        onChange={changeHandler6}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Vote ID Back
                      </Label>

                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="voterIdBackImg"
                        value={form.voterIdBackImg}
                        onChange={changeHandler7}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                {form.documentType == "passport" ? (
                  <>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Passport Front
                      </Label>

                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="passportFrontImg"
                        value={form.passportFrontImg}
                        onChange={changeHandler8}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Passport Back
                      </Label>

                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input3"
                        name="passportBackImg"
                        value={form.passportBackImg}
                        onChange={changeHandler9}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {form.documentType == "aadhar" ? (
                  <>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Aadhar No. <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Aadhar No."
                        required
                        name="aadharNumber"
                        value={form.aadharNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Aadhar Front
                      </Label>

                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="aadharFrontImg"
                        value={form.aadharFrontImg}
                        onChange={changeHandler4}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Aadhar Back
                      </Label>

                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input3"
                        name="aadharBackImg"
                        value={form.aadharBackImg}
                        onChange={changeHandler5}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Driving License Number
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input3"
                    placeholder="license Number"
                    required
                    name="dlNumber"
                    value={form.dlNumber}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Driving License front
                  </Label>

                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input3"
                    name="dlFrontImg"
                    value={form.dlFrontImg}
                    onChange={changeHandler2}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Driving License back
                  </Label>

                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input3"
                    name="dlBackImg"
                    value={form.dlBackImg}
                    onChange={changeHandler3}
                  />
                </div>
              </Row>
              <Button variant="primary" type="submit">
                submit
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </main>

      <Footer />
    </div>
  );
}

export default Cardetails;
