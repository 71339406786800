import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Table from "react-bootstrap/Table";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

function Mybookings() {
  const [show, setShow] = useState(false);
  const [form, setform] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [user, setuser] = useState([]);
  const [users, setusers] = useState([]);
  useEffect(() => {
    getCategory();
    getWallet();
  }, []);
  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/customer/gettransacthistorybyuserid",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.transactHistory);
      });
  };

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = user.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(user.length / listPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const getWallet = () => {
    var token = sessionStorage.getItem("token");
    var _id = sessionStorage.getItem("UserId");
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/customer/customerappgetdetails",
        { _id },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setusers(res.data.customerResult);
      });
  };

  const handleSubmit = (e) => {
    addCategory();
    e.preventDefault();
  };

  const addCategory = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    dataArray.append("wallet", form.wallet);

    axios
      .put(
        "https://api.freedomcars.co.in/v1/app/customer/withdrawalrequestapp-wallet",
        dataArray,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform("");
            handleClose();
            getWallet();
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  console.log(form);

  return (
    <div>
      <Header />
      <main class="main">
        <div class="container">
          <div class="row row--grid">
            <div class="col-12"></div>
          </div>
          <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
            <div class="row row--grid">
              <div class="col-12">
                <div class="cart">
                  <div class="cart__table-wrap">
                    <div class="main__title main__title--page">
                      <h5>
                        My Wallet :{" "}
                        <span style={{ color: "green" }}>
                          ₹ {users.wallet} /-
                        </span>
                      </h5>
                    </div>

                    <button
                      className="btn mb-4"
                      style={{
                        float: "right",
                        color: "white",
                        background: "#015A65",
                      }}
                      onClick={handleShow}
                    >
                      Wallet Request
                    </button>
                    {lists.length >= 1 ? (
                      <>
                        <div class="cart__table-scroll">
                          <Table bordered>
                            <thead
                              style={{ background: "#015A65", color: "white" }}
                            >
                              <tr>
                                <th>S.No</th>
                                <th>CustomerName </th>
                                <th>CustomerPhone</th>
                                <th>Customeremail</th>
                                <th>TransactionStatus</th>
                                <th>TransactionType</th>
                                <th>Wallet</th>
                              </tr>
                            </thead>

                            <tbody>
                              {lists.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{(pageNumber - 1) * 10 + i + 11}</td>
                                    <td>{data.customerName}</td>
                                    <td>{data.customerPhone}</td>
                                    <td>{data.customeremail}</td>
                                    <td>{data.transactionStatus}</td>
                                    <td>{data.transactionType}</td>

                                    {data.transactionType == "Credit" ? (
                                      <>
                                        {" "}
                                        <td style={{ color: "green" }}>
                                          + {data.wallet} ₹
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td style={{ color: "red" }}>
                                          - {data.wallet} ₹
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ height: "240px" }}>
                        <center style={{ marginTop: "200px" }}>
                          <h4>No Data Found</h4>
                        </center>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Wallet Request :{" "}
              <span style={{ color: "green" }}>₹ {users.wallet} /-</span>
            </Modal.Title>
          </Modal.Header>
          <form
            method="post"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            class="modal__form"
          >
            <Modal.Body>
              <div class="sign__group">
                <label for="fullname" class="sign__label sign__label--modal">
                  Enter Amount
                </label>
                <input
                  type="text"
                  value={form.wallet}
                  placeholder="Enter Amount"
                  className="form-control"
                  name="wallet"
                  required
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                submit
              </Button>
              <Button type="button" variant="danger" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <ToastContainer />
      </main>
      <Footer />
    </div>
  );
}

export default Mybookings;
