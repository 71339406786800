import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import logo from "../img/freedom4.png";

function Signup() {
  const [form, setform] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    add();
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };


  const add = () => {
    const dataArray = new FormData();
    dataArray.append("customerName", form.customerName);
    dataArray.append("email", form.email);
    dataArray.append("password", form.password);
    dataArray.append("phone", form.phone);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/customer/customerappselfregister",
        dataArray,
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setTimeout(() => {
              navigate("/login");
              toast.dismiss();
            }, 2000);
            clearForm();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const clearForm = () => {
    setform({
      customerName: "",
      email: "",
      password: "",
      phone: "",
    });
  };

  return (
    <div>
      <main className="main main--sign" data-bg="img/bg/bg.png">
        <div className="sign">
          <div className="sign__content">
            <form
              className="sign__form"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              method="post"
            >
              <a className="sign__logo">
                {/* <h3 className="zz">freedom cars</h3> */}
                <img src={logo} width="200px"></img>
              </a>

              <div className="sign__group">
                <input
                  type="text"
                  className="sign__input"
                  placeholder="Enter your Name"
                  required
                  name="customerName"
                  pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={form.customerName}
                />
              </div>
              <div className="sign__group">
                <input
                  type="text"
                  className="sign__input"
                  placeholder="Enter your Mobile"
                  required
                  name="phone"
                  maxlength="10"
                  pattern="\d{10}"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={form.phone}
                />
              </div>

              <div className="sign__group">
                <input
                  type="email"
                  className="sign__input"
                  placeholder="Enter your Email"
                  required
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={form.email}
                />
              </div>

              <div class="sign__group">
                  <input
                    name="password"
                    class="sign__input"
                    value={form.password}
                    type={showPassword ? 'text' : 'password'}
                    required
                    minLength={6}
                    placeholder="Enter Password"
                    onChange={(e) => {
                      handleChange(e);
                    }}

                  />
                  <div className="textps">
                  <i
                    className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                    aria-hidden="true"
                    onClick={togglePasswordVisibility}
                  />
                  </div>
                </div>



              {/* <div className="sign__group">
                <input
                  type="password"
                  className="sign__input"
                  placeholder="Enter Password"
                  required
                  minLength={6}
                  // pattern="[A-Za-z\d]{6,}$"
                  name="password"
                
                 
                />
              </div> */}

              {/* <div className="sign__group sign__group--checkbox">
                <input id="remember" name="remember" type="checkbox" />
                <label for="remember">
                  I agree to the Terms and Conditions
                </label>
              </div> */}

              <button className="sign__btn" type="submit">
                <span>Sign up</span>
              </button>

              <span className="sign__text">
                Already have an account?
                <NavLink to="/login">
                  <a>Sign in!</a>
                </NavLink>
              </span>
            </form>

            <ToastContainer />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Signup;
