import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Doc() {
  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };


  const handleChanges = (e) => {
    let myUser = { ...form };
    myUser.countryId = e.target.value;
    setform(myUser);
  };
  const [form, setform] = useState([]);
  const [Files, setFiles] = useState("");
  const changeHandler = (e) => {
    setFiles(e.target.files);
  };
  const [Files1, setFiles1] = useState("");
  const [Files2, setFiles2] = useState("");
  const [Files3, setFiles3] = useState("");
  const [Files4, setFiles4] = useState("");
  const [Files5, setFiles5] = useState("");
  const [Files6, setFiles6] = useState("");
  const [Files7, setFiles7] = useState("");
  const [Files8, setFiles8] = useState("");
  const [Files9, setFiles9] = useState("");
  const [Files10, setFiles10] = useState("");
  const [Files11, setFiles11] = useState("");
  const [Files12, setFiles12] = useState("");
  const [Files13, setFiles13] = useState("");
  const [Files14, setFiles14] = useState("");
  // const [Files3, setFiles3] = useState("");

  const changeHandler1 = (e) => {
    setFiles1(e.target.files);
  };
  const changeHandler2 = (e) => {
    setFiles2(e.target.files);
  };
  const changeHandler3 = (e) => {
    setFiles3(e.target.files);
  };
  const changeHandler4 = (e) => {
    setFiles4(e.target.files);
  };
  const changeHandler5 = (e) => {
    setFiles5(e.target.files);
  };
  const changeHandler6 = (e) => {
    setFiles6(e.target.files);
  };
  const changeHandler7 = (e) => {
    setFiles7(e.target.files);
  };
  const changeHandler8 = (e) => {
    setFiles8(e.target.files);
  };
  const changeHandler9 = (e) => {
    setFiles9(e.target.files);
  };
  const changeHandler10 = (e) => {
    setFiles10(e.target.files);
  };
  const changeHandler11 = (e) => {
    setFiles11(e.target.files);
  };
  const changeHandler12 = (e) => {
    setFiles12(e.target.files);
  };
  const changeHandler13 = (e) => {
    setFiles13(e.target.files);
  };
  const changeHandler14 = (e) => {
    setFiles14(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editcutomer();
  };

  const navigate = useNavigate();

  const editcutomer = () => {
    var token = sessionStorage.getItem("token");

    const dataArray = new FormData();
    dataArray.append("documentType", form.documentType);
    if(form.documentType == "aadhar"){
      dataArray.append("aadharNumber", form.aadharNumber);
      for (let i = 0; i < Files4.length; i++) {
        dataArray.append("aadharFrontImg", Files4[i]);
      }
      for (let i = 0; i < Files5.length; i++) {
        dataArray.append("aadharBackImg", Files5[i]);
      }
    }else if(form.documentType == "voterId"){
      dataArray.append("voterIdNumber", form.voterIdNumber);
      for (let i = 0; i < Files6.length; i++) {
        dataArray.append("voterIdFrontImg", Files6[i]);
      }
      for (let i = 0; i < Files7.length; i++) {
        dataArray.append("voterIdBackImg", Files7[i]);
      }
    }else if(form.documentType == "passport"){
      for (let i = 0; i < Files8.length; i++) {
        dataArray.append("passportFrontImg", Files8[i]);
      }
      for (let i = 0; i < Files9.length; i++) {
        dataArray.append("passportBackImg", Files9[i]);
      }
    }else{
      
    }
   
    dataArray.append("dlNumber", form.dlNumber);

    for (let i = 0; i < Files12.length; i++) {
      dataArray.append("interPassportImg", Files12[i]);
    }
    for (let i = 0; i < Files13.length; i++) {
      dataArray.append("interDlFrontImg", Files13[i]);
    }
    for (let i = 0; i < Files14.length; i++) {
      dataArray.append("interDlBackImg", Files14[i]);
    }

    for (let i = 0; i < Files11.length; i++) {
      dataArray.append("visaImg", Files11[i]);
    }

    for (let i = 0; i < Files2.length; i++) {
      dataArray.append("dlFrontImg", Files2[i]);
    }
    for (let i = 0; i < Files3.length; i++) {
      dataArray.append("dlBackImg", Files3[i]);
    }

    for (let i = 0; i < Files10.length; i++) {
      dataArray.append("photoidImg", Files10[i]);
    }

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("selfieImg", Files[i]);
    }

    axios
      .put(
        "https://api.freedomcars.co.in/v1/app/customer/editapp-customerdocuments",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            clearForm();
            sessionStorage.setItem("status", res.data.status);
            navigate("/");
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const clearForm = () => {
    setform({
      // profileImg: "",
      documentType: "",
      voterIdNumber: "",
      voterIdFrontImg: "",
      voterIdBackImg: "",
      passportFrontImg: "",
      passportBackImg: "",
      aadharNumber: "",
      aadharFrontImg: "",
      aadharBackImg: "",
      dlNumber: "",
      dlFrontImg: "",
      dlBackImg: "",
      selfieImg: "",
    });
  };

  return (
    <>
      <Header />
      <div>
   
          <main class="main">
            <Container
              class="mt-5"
              style={{ marginTop: "120px", marginBottom: "20px" }}
            >
              <div class="col-12 mt-5">
                <div class="main__title main__title--page">
                  <div class="details">
                    <h5>Add Documents</h5>
                    <div class=" splide--details details__slider">
                      <form
                        onSubmit={(e) => {
                          handleSubmit(e);
                        }}
                        class="modal__form"
                      >
                        <Row className="mt-3">
                          <Col md={3} className="mb-3">
                            <Label
                              className="mr-4"
                              for="basicpill-firstname-input1"
                            >
                             Selfie Images
                            </Label>

                            <Input
                              type="file"
                              className="form-control"
                              id="basicpill-firstname-input1"
                              placeholder="Enter Image"
                              name="selfieImg"
                              value={form.selfieImg}
                              onChange={changeHandler}
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Select Aadhar / vote / Passport
                              <span className="text-danger">*</span>
                            </Label>

                            <select
                              name="documentType"
                              required
                              value={form.documentType}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              // onChange={handleSelectChange3}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="aadhar">Aadhar</option>
                              <option value="voterId">Voter Id</option>
                              <option value="passport">Passport</option>
                            </select>
                          </Col>

                          {form.documentType == "voterId" ? (
                            <>
                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Vote Id <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  maxLength="10"
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  placeholder=" Vote Id"
                                  required
                                  name="voterIdNumber"
                                  value={form.voterIdNumber}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>

                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                  Vote ID Front{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <Input
                                  type="file"
                                  required
                                  className="form-control"
                                  id="basicpill-firstname-input3"
                                  name="voterIdFrontImg"
                                  value={form.voterIdFrontImg}
                                  onChange={changeHandler6}
                                />
                              </Col>

                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Vote ID Back{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <Input
                                  type="file"
                                  required
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  name="voterIdBackImg"
                                  value={form.voterIdBackImg}
                                  onChange={changeHandler7}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {form.documentType == "passport" ? (
                            <>
                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Passport Front{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <Input
                                  type="file"
                                  required
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  name="passportFrontImg"
                                  value={form.passportFrontImg}
                                  onChange={changeHandler8}
                                />
                              </Col>

                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                  Passport Back{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <Input
                                  type="file"
                                  required
                                  className="form-control"
                                  id="basicpill-firstname-input3"
                                  name="passportBackImg"
                                  value={form.passportBackImg}
                                  onChange={changeHandler9}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          {form.documentType == "aadhar" ? (
                            <>
                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Aadhar No.{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  maxlength="12"
                                  pattern="\d{12}"
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  placeholder="Aadhar No."
                                  required
                                  name="aadharNumber"
                                  value={form.aadharNumber}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>

                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Aadhar Front{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <Input
                                  type="file"
                                  required
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  name="aadharFrontImg"
                                  value={form.aadharFrontImg}
                                  onChange={changeHandler4}
                                />
                              </Col>

                              <Col md={3} className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                  Aadhar Back{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <Input
                                  type="file"
                                  required
                                  className="form-control"
                                  id="basicpill-firstname-input3"
                                  name="aadharBackImg"
                                  value={form.aadharBackImg}
                                  onChange={changeHandler5}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          <Col md={3} className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Driving License Number
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              maxLength="16"
                              className="form-control"
                              id="basicpill-firstname-input3"
                              placeholder="license Number"
                              required
                              name="dlNumber"
                              value={form.dlNumber}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Driving License front{" "}
                              <span className="text-danger">*</span>
                            </Label>

                            <Input
                              type="file"
                              required
                              className="form-control"
                              id="basicpill-firstname-input3"
                              name="dlFrontImg"
                              value={form.dlFrontImg}
                              onChange={changeHandler2}
                            />
                          </Col>

                          <Col md={3} className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Driving License back{" "}
                              <span className="text-danger">*</span>
                            </Label>

                            <Input
                              type="file"
                              required
                              className="form-control"
                              id="basicpill-firstname-input3"
                              name="dlBackImg"
                              value={form.dlBackImg}
                              onChange={changeHandler3}
                            />
                          </Col>
                        </Row>
                        <div className="mt-3 mr-3" style={{ float: "right" }}>
                          <Button
                            style={{ width: "100px" }}
                            variant="primary"
                            color="primary"
                            type="submit"
                          >
                            submit
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <section class="row row--grid"> </section>
            </Container>
          </main>
          <ToastContainer />
        </div>
      
      <Footer />
    </>
  );
}

export default Doc;
