import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function Contact() {
  const [form, setform] = useState([]);
  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    add();
  };

  const add = () => {
    const dataArray = new FormData();
    dataArray.append("customerName", form.customerName);
    dataArray.append("email", form.email);
    dataArray.append("subject", form.subject);
    dataArray.append("description", form.description);
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/general/addcontactus",
        dataArray,
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            clearForm();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const clearForm = () => {
    setform({
      customerName: "",
      email: "",
      subject: "",
      description: "",
    });
  };

  return (
    <div>
      <Header />
      <main class="main">
        <div class="container">
          <div class="row row--grid">
            <div class="col-12">
              <div class="main__title main__title--page">
                <h1>Get in touch</h1>
              </div>
            </div>
          </div>

          <div class="row row--grid">
            <div class="col-12 col-lg-7 col-xl-8">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                method="post"
                class="sign__form sign__form--contacts"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="sign__group">
                      <input
                        type="text"
                        className="sign__input"
                        placeholder="Enter your Name"
                        required
                        name="customerName"
                        pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={form.customerName}
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="sign__group">
                      <input
                        type="email"
                        className="sign__input"
                        placeholder="Enter your Email"
                        required
                        name="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={form.email}
                      />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="sign__group">
                      <input
                        type="text"
                        className="sign__input"
                        required
                        name="subject"
                        pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={form.subject}
                        placeholder="Subject"
                      />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="sign__group">
                      <textarea
                        className="sign__input"
                        placeholder="Type your message"
                        required
                        name="description"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={form.description}
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-xl-3">
                    <button type="submit" class="sign__btn">
                      <span>Send</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-12 col-md-6 col-lg-5 col-xl-4">
              <div class="main__title main__title--sidebar">
                <h2>Info</h2>
              </div>
              <ul class="contacts__list">
                <li>
                  <a href="tel:88002345678">961 333 3366</a>
                </li>
                <li>
                  <a
                    href="mailto:Support@freedomcars.co.in
"
                  >
                    Info@thefreedomcars.com support@freedomcars.co.in
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/ak7MeBU7beWeeFz69"
                    class="open-map"
                  >
                    Plot No : 46, Golden gate tower Hills ,Madhapur, Hyderabad,
                    Telangana 500081.
                  </a>
                </li>
              </ul>
              <div class="contacts__social" style={{ paddingLeft: "32px" }}>
              <a
                  href="https://play.google.com/store/apps/details?id=com.rental.freedomcars"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-google-play"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z" />
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/people/Freedom-Car/100084109016636/"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z" />
                  </svg>
                </a>

                <a
                  href="https://www.instagram.com/freedom_cars_hyd/?igshid=YmMyMTA2M2Y%3D"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z" />
                  </svg>
                </a>

                <a href="https://wa.me/9613333366" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />{" "}
                  </svg>
                </a>

                <a href="#" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Contact;
