import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Table from "react-bootstrap/Table";

function Mybookings() {
  const [user, setuser] = useState([]);
  const [user1, setuser1] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.freedomcars.co.in/v1/app/booking/drivepoints",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser1(res.data.DrivePoints);
        setuser(res.data.points);
      });
  };

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = user.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(user.length / listPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <Header />
      <main className="main">
        <div className="container">
          <div className="row row--grid">
            <div className="col-12 mt-5">
              <h5>
                Drive Points :{" "}
                <span style={{ color: "green" }}>{user1} Points</span>
              </h5>
            </div>
          </div>
          <div>
            {lists.length >= 1 ? (
              <div className="row row--grid">
                <div className="col-12">
                  <div className="cart">
                    <div className="cart__table-wrap">
                      <h5>Points History :</h5>
                      <div className="cart__table-scroll">
                        <div>
                          <div className="table-responsive">
                            <Table bordered>
                              <thead
                                style={{
                                  background: "#015A65",
                                  color: "white",
                                }}
                              >
                                <tr>
                                  <th>S.No</th>
                                  <th>Date</th>
                                  <th>Booking Id </th>
                                  <th>Drive points</th>
                                </tr>
                              </thead>

                              <tbody>
                                {lists.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(pageNumber - 1) * 10 + i + 11}</td>
                                      <td>
                                        {data.date}
                                      </td>
                                      <td>{data.booking_id}</td>
                                      <td>{data.drivepoints}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>

                            <div className="mt-3" style={{ float: "right" }}>
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists.length}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ height: "210px" }}>
                <center style={{ marginTop: "120px" }}>
                  <h4>No Data Found</h4>
                </center>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Mybookings;
